import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { ACCOUNT_URL, SETTING_URL } from '../../routes';
import { PrivateRoute, PublicRoute } from '../../routing';
import NotFound from '../NotFound';
import Account from './Account';

export default function Setting() {
  return (
    <Switch>
      <PrivateRoute path={ACCOUNT_URL} component={Account} />

      <Redirect exact from={SETTING_URL} to={ACCOUNT_URL} />
      <PublicRoute component={NotFound} />
    </Switch>
  );
}
