import { AccordionSummary, AccordionDetails, Accordion } from '@mui/material';

import { TestResult } from '../service/Tests';
import { memo, useState } from 'react';
import { ExpandMore } from '@material-ui/icons';
import { XMLParser } from 'fast-xml-parser';

// interface Props {
//   isDataScript: boolean;
//   result: TestResult;
// }

function DataScript(props: any) {
  const { result } = props;
  const [expanded, setExpanded] = useState(false);
  // const [results, setResult] = useState<TestResult[]>([]);
  let { Data } = result?.responseData[0];

  if (!Data) return <></>;
  return (
    <Accordion expanded={expanded} elevation={2} className="rounded-none shadow border-t">
      <AccordionSummary
        onClick={() => {
          if (result) setExpanded(!expanded);
        }}
        className={`transition-colors duration-700 flex items-center justify-between`}
        expandIcon={<ExpandMore />}
      >
        {Data[0].uid ? Data[0].uid.replace(/'|"|{|}/g, '') : 'noUid'}
      </AccordionSummary>
      <AccordionDetails>
        <div className="mb-4">
          <div className="box-container">
            <table className="w-full">
              <thead className="heading">
                <tr>
                  <td className="p-2 text-start text-white">Name</td>
                  <td className="p-2 text-start text-white">Value</td>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(Data) &&
                  Data.map(({ payload_data }, index) => {
                    let payload = '';
                    if (payload_data) {
                      const parser = new XMLParser();
                      payload = parser.parse(payload_data);
                    }
                    if (payload === '')
                      return (
                        <tr key={index}>
                          <td className="p-2 bg-gray-300 w-2/12">UID</td>
                          <td className="p-2 w-10/12">{Data[0].uid.replace(/'|"|{|}/g, '')}</td>
                        </tr>
                      );
                    return Object.entries(payload).map(([typename, type], index) => {
                      if (typename === '?xml') return [<></>];

                      const rows: JSX.Element[] = [];
                      rows.push(
                        ...Object.entries(type).map(([name, value], index) => (
                          <tr key={`name_${index}`}>
                            <td className="p-2 bg-gray-300 w-2/12">{name}</td>
                            <td className="p-2 w-10/12">
                              {JSON.stringify(value, null, 2).replace(/'|"|{|}/g, '')}
                            </td>
                          </tr>
                        ))
                      );
                      while (rows.length > 3) {
                        rows.pop();
                      }
                      rows.push(
                        <tr key={index}>
                          <td className="p-2 bg-gray-300 w-2/12">XML</td>
                          <td className="p-2 w-10/12">{payload_data}</td>
                        </tr>
                      );

                      return rows;
                    });
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default memo(DataScript);
