import { useEffect, useState } from 'react';
import {
  Modal,
  Button,
  FormControl,
  TextField,
  FormHelperText,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
// import Add from '@mui/icons-material/Add';
import { Controller, useForm, useWatch } from 'react-hook-form';
import toast from 'react-hot-toast';
import { addSuit, Suite, updateSuit } from '../service/Tests';
import { AxiosError } from 'axios';

interface FormState {
  name: string;
  validForCertification: boolean;
}

interface Props {
  AfterSubmit?: (value: FormState) => void;
  edit: boolean;
  initialState?: Suite;
  setEdit: (active: boolean) => void;
  setActive: (active: Suite) => void;
}

export default function AddSuiteModel(props: Props) {
  const { edit, setEdit, AfterSubmit, initialState: initialState, setActive } = props;
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('Add Suite');

  const { handleSubmit, control, setValue, reset } = useForm<FormState>({
    defaultValues: {
      name: '',
      validForCertification: false,
    },
  });

  useEffect(() => {
    if (edit) setOpen(true);
  }, [edit, reset]);

  useEffect(() => {
    reset(initialState);
    setTitle('Update Suite');
  }, [initialState]);

  const validForCertification = useWatch({ control, name: 'validForCertification' });

  async function OnSubmit(form: FormState) {
    if (initialState?._id) {
      toast.promise(updateSuit(initialState._id, form.name, form.validForCertification), {
        loading: 'Loading',
        success: () => {
          AfterSubmit?.(form);
          setOpen(false);
          setEdit(false);
          return `Successfully Updated`;
        },
        error: ({ response }: AxiosError) => response?.data.message ?? 'Some Error Occured',
      });
    } else {
      toast.promise(addSuit(form.name, form.validForCertification), {
        loading: 'Loading',
        success: () => {
          AfterSubmit?.(form);
          setOpen(false);
          setEdit(false);
          setActive({
            name: '',
            slug: '',
            suspended: false,
            validForCertification: false,
            _id: '',
          });
          return `Successfully Added`;
        },
        error: ({ response }: AxiosError) => response?.data.message ?? 'Some Error Occured',
      });
    }
    // const action = initialState?._id ? updateSuit : addSuit;
  }

  return (
    <>
      <div className="back-button flex justify-start column w-6/12 "></div>
      <div className="add-new flex justify-end column w-6/12">
        <Button variant="contained" disableElevation onClick={() => setOpen(true)}>
          Add New
        </Button>
      </div>

      <Modal
        className="outline-0 flex items-center justify-center"
        open={open}
        onClose={() => {
          setOpen(false);
          setEdit(false);
          setActive({
            name: '',
            slug: '',
            suspended: false,
            validForCertification: false,
            _id: '',
          });
        }}
      >
        <section className="modal-container">
          <div className="section-title text-3xl">{title}</div>
          <form className="mt-2" onSubmit={handleSubmit(OnSubmit)}>
            <FormControl fullWidth className="label label-small mr-2">
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <TextField
                      size="small"
                      value={value ?? ''}
                      label="Suit Name"
                      name={name}
                      onBlur={onBlur}
                      inputRef={ref}
                      onChange={onChange}
                      inputProps={{ maxLength: 60 }}
                      error={!!error}
                    />

                    <FormHelperText className="text-red-800">{error?.message}</FormHelperText>
                  </>
                )}
                name="name"
                control={control}
              />
            </FormControl>

            <FormControlLabel
              control={
                <Checkbox
                  value={validForCertification}
                  checked={validForCertification}
                  onChange={(_, check) => {
                    setValue('validForCertification', check);
                  }}
                  color="primary"
                />
              }
              label="validForCertification"
              className="text-primary"
              labelPlacement="end"
            />
            <Button variant="contained" disableElevation onClick={handleSubmit(OnSubmit)}>
              Save
            </Button>
          </form>
        </section>
      </Modal>
    </>
  );
}
