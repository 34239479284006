import { Delete, Edit } from '@mui/icons-material';
import { useEffect, useMemo, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import toast from 'react-hot-toast';
import { AxiosError } from 'axios';
import { Button } from '@mui/material';
import { Column } from '../../types';
import { activatecompany, Company, deletecompany, getcompanies } from '../../service/Company';
import { DEFAULT_PAGE_SIZE } from '../../constant';
import { havePermission } from '../../service/User';
import { useConfirm } from 'material-ui-confirm';

interface Props {
  extraHeaderContent?: JSX.Element;
  onEdit?: (data: Company) => void;
  disablHeader?: boolean;
  showRevoked: boolean;
  className?: string;
}

export default function CompanyList(props: Props) {
  const { extraHeaderContent, onEdit, disablHeader, className, showRevoked = true } = props;
  const confirm = useConfirm();
  const [data, setData] = useState<Company[]>([]);
  const [loading, setLoading] = useState(true);
  const [filterdata, setFilterdata] = useState<Company[]>([]);

  useEffect(() => {
    let filterdata = showRevoked ? data : data.filter((item) => !item.suspended);
    setFilterdata(filterdata);
  }, [data, showRevoked]);

  useEffect(() => {
    if (loading && data.length === 0)
      getcompanies()
        .then((data) => {
          const indexeddata = data.map((company) => ({ ...company, id: company._id }));
          setData(indexeddata);
        })
        .catch(({ response }: AxiosError) =>
          toast.error(response?.data.message ?? 'Some Error Occured')
        )
        .finally(() => setLoading(false));
  }, [data, loading]);

  function onDelete(company: Company) {
    toast.promise(deletecompany(company), {
      loading: 'Loading',
      success: () => {
        setData([]);
        setLoading(true);
        return `Successfully Deactivated`;
      },
      error: ({ response }: AxiosError) => response?.data.message ?? 'Some Error Occured',
    });
  }

  function onActive(company: Company) {
    toast.promise(activatecompany(company), {
      loading: 'Loading',
      success: () => {
        setData([]);
        return `Successfully Activated`;
      },
      error: ({ response }: AxiosError) => response?.data.message ?? 'Some Error Occured',
    });
  }
  const columns: Column<Company>[] = useMemo(() => {
    const col: Column<Company>[] = [
      {
        flex: 1,
        field: 'name',
        headerName: 'Name',
        headerClassName: 'pl-custom-0',
        renderCell: (data) => {
          const { name } = data.row as Company;
          return <div className="flex items-center">{name}</div>;
        },
      },
      { flex: 1, field: 'email', headerClassName: 'pl-custom-0', headerName: 'Primary Contact' },
      {
        flex: 1,
        field: 'status',
        headerName: 'Status',
        headerClassName: 'pl-custom-0',
        renderCell: (data) => {
          const { suspended } = data.row as Company;
          return suspended ? <div className="text-red-600">Inactive</div> : <div>Active</div>;
        },
      },
    ];

    if (havePermission(['super-admin', 'psia-admin', 'psia-user']))
      col.push({
        flex: 1,
        field: '',
        headerName: 'Actions',

        cellClassName: 'flex items-center',
        renderCell: (data) => {
          const { suspended } = data.row as Company;

          return (
            <>
              <Button disableElevation variant="contained" onClick={() => onEdit?.(data.row)}>
                <Edit className="mr-2" fontSize="small" /> Edit
              </Button>
              {havePermission(['psia-admin']) && (
                <>
                  {suspended ? (
                    <Button
                      disableElevation
                      variant="contained"
                      color="error"
                      onClick={() => onActive(data.row)}
                    >
                      <Delete className="mr-2" fontSize="small" /> Activate
                    </Button>
                  ) : (
                    <Button
                      className="ml-2"
                      disableElevation
                      variant="contained"
                      color="error"
                      onClick={() => {
                        confirm({ description: `Are you sure you want to do this?` })
                          .then(() => {
                            onDelete(data.row);
                          })
                          .catch(() => console.log('Deletion cancelled.'));
                        // onDelete(data.row)
                      }}
                    >
                      <Delete className="mr-2" fontSize="small" /> Deactivate
                    </Button>
                  )}
                </>
              )}
            </>
          );
        },
      });

    return col;
  }, []);

  return (
    <section className={className}>
      {!disablHeader && (
        <div className="section-title">
          <div>Company</div>
          {extraHeaderContent}
        </div>
      )}
      <div className="box-container">
        <DataGrid
          disableColumnMenu
          rows={filterdata}
          loading={loading}
          columns={columns}
          pageSize={DEFAULT_PAGE_SIZE}
          autoHeight={true}
          disableSelectionOnClick={true}
        />
      </div>
    </section>
  );
}
