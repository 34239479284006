import CertificatesList from '../section/CertificatesList';
import LatestTests from '../section/LatestTests';
import { getDashBoardMenu, DashboardMenu } from '../service/DashboardMenu';
import { useEffect, useState } from 'react';
import CardMembershipOutlinedIcon from '@mui/icons-material/CardMembershipOutlined';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';

const Dashboard = () => {
  const [data, setData] = useState<DashboardMenu>();
  const [currTime, setcurrTime] = useState<string>(
    new Date().toLocaleString('en-GB', { month: 'long', weekday: 'long' })
  );

  useEffect(() => {
    getDashBoardMenu().then((dashboardData) => {
      setData(dashboardData);
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const event = new Date();
      let time = event.toLocaleDateString(undefined, {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      });
      setcurrTime(time);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col">
      <section>
        <div className="flex">
          <div className="section-title flex justify-start column w-6/12">Dashboard</div>
          <div className="flex justify-end column w-6/12"> {currTime}</div>
        </div>

        <div className="flex-grid no-space">
          <div className="column pr-2">
            <div className="flex-grid lg:items-center rounded-lg bg-white shadow-lg p-4 h-full">
              <div className="column flex-none w-full lg:w-auto mb-2">
                <div className="rounded-full bg-blue-900 text-white icon items-center justify-center text-7xl">
                  {/* <i className="ri-draft-line text-4xl" /> */}
                  {<DocumentScannerOutlinedIcon />}
                </div>
              </div>
              <div className="column px-2">
                <div className="text-gray-600">Total Running Tests</div>
                <div className="text-4xl font-bold text-blue-900">{data?.totalRunningTests}</div>
              </div>
            </div>
          </div>
          <div className="column px-2">
            <div className="flex-grid lg:items-center rounded-lg bg-white shadow-lg p-4 h-full">
              <div className="column flex-none w-full lg:w-auto mb-2">
                <div className="rounded-full bg-yellow-500 text-white icon items-center justify-center text-7xl">
                  {/* <i className="ri-draft-line text-4xl" /> */}
                  {<DocumentScannerOutlinedIcon />}
                </div>
              </div>
              <div className="column px-2">
                <div className="text-gray-600">Total Test Runs</div>
                <div className="text-4xl font-bold text-yellow-500">{data?.totalTestsRuns}</div>
              </div>
            </div>
          </div>
          <div className="column px-2">
            <div className="flex-grid lg:items-center rounded-lg bg-white shadow-lg p-4 h-full">
              <div className="column flex-none w-full lg:w-auto mb-2">
                <div className="rounded-full bg-blue-400 text-white icon items-center justify-center text-7xl">
                  {/* <i className="ri-check-line text-4xl" /> */}
                  {<DoneAllOutlinedIcon />}
                </div>
              </div>
              <div className="column px-2">
                <div className="text-gray-600">Total Successful Test</div>
                <div className="text-4xl font-bold text-blue-500">
                  {data?.totalSuccessfullTests}
                </div>
              </div>
            </div>
          </div>
          <div className="column pl-2">
            <div className="flex-grid lg:items-center rounded-lg bg-white shadow-lg p-4 h-full">
              <div className="column flex-none w-full lg:w-auto mb-2">
                <div className="rounded-full bg-red-700 text-white icon items-center justify-center text-7xl">
                  {<CardMembershipOutlinedIcon />}
                </div>
              </div>
              <div className="column px-2">
                <div className="text-gray-600">Total Certificates</div>
                <div className="text-4xl font-bold text-red-700">{data?.totalCertificates}</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <LatestTests suites={data} page={5} />

      <CertificatesList disablFilter />
    </div>
  );
};
export default Dashboard;
