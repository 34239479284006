import { useHistory } from 'react-router';
import { RUN_TESTS_URL } from '../routes';

import TestResults from '../section/TestResults';
import { Button } from '@mui/material';

export default function TestsResults() {
  const history = useHistory();

  return (
    <section>
      <div className="section-title">
        <div> Tests Results</div>
        <Button
          disableElevation
          size="large"
          variant="contained"
          onClick={() => history.push(RUN_TESTS_URL)}
        >
          Run New Test
        </Button>
      </div>
      <div className="box-container">
        <TestResults />
      </div>
    </section>
  );
}
