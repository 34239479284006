import api, { Response } from '../utils/api';

export interface Company {
  _id: string; // '61a6341c1dfa3100831ed015';
  name: string; // 'zeeshan rafi';
  country: string; // 'jdslafjsf';
  state: string; // 'punjab';
  city: string; // 'lahore';
  zip: number; // 68400;
  address: string; // 'aflaaaa';
  contact: string; // '65446136416';
  email: string; // 'zeeshan.rafi@purelogics.com';
  status: boolean; // true;
  suspended: boolean; // false;
  registrationNo: string; // '654654';
  noOfEmployee: number; // 25;
  noOfUser: number; // 0;
}

export async function getcompany(id: number) {
  const { data: response } = await api.get<Response<Company>>(`/company/${id}`);
  return response.data;
}

export async function addcompany(data: Company) {
  const { data: response } = await api.post<Company>(`/company`, data);
  return response;
}

export async function updatecompany(data: Company) {
  const { data: response } = await api.put<Company>(`/company/${data._id}`, data);
  return response;
}

export async function deletecompany(data: Company) {
  const { data: response } = await api.get<Company>(`/company/suspend/${data._id}`);
  return response;
}

export async function activatecompany(data: Company) {
  const { data: response } = await api.get<Company>(`/company/unsuspend/${data._id}`);
  return response;
}

export async function getcompanies() {
  const { data: response } = await api.get<Response<Company[]>>(`/company`);
  return response.data;
}
