import { useEffect, useMemo, useState } from 'react';
import { Column } from '../types';
import { DataGrid } from '@mui/x-data-grid';
import {
  Certificate,
  downloadCertificates,
  getCertificates,
  suspendCertificates,
} from '../service/Certificates';
import toast from 'react-hot-toast';
import { AxiosError } from 'axios';
import {
  Avatar,
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  TextField,
} from '@mui/material';
import { FileDownloadOutlined } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import { DEFAULT_IMAGE_URL, DEFAULT_PAGE_SIZE } from '../constant';
import { havePermission } from '../service/User';
import { useConfirm } from 'material-ui-confirm';

interface Props {
  extraHeaderContent?: JSX.Element;
  disablHeader?: boolean;
  disablFilter?: boolean;
}

export default function CertificatesList(props: Props) {
  const { extraHeaderContent, disablFilter, disablHeader } = props;
  const [data, setData] = useState<Certificate[]>([]);
  const [filterdata, setFilterdata] = useState<Certificate[]>([]);
  const [loading, setLoading] = useState(true);
  const [multiselect, setMultiselect] = useState(false);
  const [showrevoked, setShowrevoked] = useState(false);
  const [selected, setSelected] = useState<Certificate[]>([]);
  const [search, setSearch] = useState('');
  const confirm = useConfirm();

  useEffect(() => {
    if (loading && data.length === 0)
      getCertificates()
        .then((data) => {
          setData(data);
        })
        .catch(({ response }: AxiosError) =>
          toast.error(response?.data.message ?? 'Some Error Occured')
        )
        .finally(() => setLoading(false));
  }, [data, loading]);

  const columns: Column<Certificate>[] = useMemo(() => {
    const col: Column<Certificate>[] = [
      { field: 'testPerformedDate', headerName: 'Date & Time', flex: 1 },
      {
        field: 'suitName',
        headerName: 'Suite Name',
        flex: 1,
        headerClassName: 'pl-custom-0',
        cellClassName: 'flex items-center',
      },
      {
        field: 'companyName',
        headerName: 'Company',
        flex: 1,
        headerClassName: 'pl-custom-0',
      },
      {
        field: 'userId',
        headerName: 'User',
        flex: 1,
        headerClassName: 'pl-custom-0',
        renderCell: (data) => {
          const { userLastName, userProfileImage } = data.row as Certificate;

          return (
            <div className="flex items-center justify-center">
              <Avatar
                alt={userLastName}
                src={DEFAULT_IMAGE_URL + userProfileImage}
                className="mr-2"
              />
              {userLastName}
            </div>
          );
        },
      },
      {
        field: 'suspended',
        headerName: 'Status',
        headerClassName: 'pl-custom-0',
        cellClassName: 'flex items-center',
        flex: 1,
        renderCell: (data) => {
          const { suspended } = data.row as Certificate;

          return suspended ? (
            <div className="flex items-center">
              <i className="text-2xl text-primary ri-close-circle-fill mr-2" /> Revoked
            </div>
          ) : (
            <div className="flex items-center">
              <i className="text-2xl text-primary ri-checkbox-fill mr-2" />
              Active
            </div>
          );
        },
      },
      {
        field: '',
        headerName: 'Action',
        flex: 2,
        headerClassName: 'pl-custom-0',
        cellClassName: 'flex items-center',
        renderCell: (data) => {
          const { _id, suspended } = data.row as Certificate;
          return suspended ? (
            <></>
          ) : (
            <>
              <Button
                className="mr-2"
                startIcon={<FileDownloadOutlined />}
                variant="contained"
                color="primary"
                onClick={() => {
                  const loading = toast.loading('Generating Certificate');
                  downloadCertificates(_id)
                    .catch(() => toast.error('Error Generating Certificate'))
                    .finally(() => toast.dismiss(loading));
                }}
              >
                Download
              </Button>
              {havePermission(['psia-admin', 'psia-user']) && (
                <Button
                  startIcon={<CancelIcon />}
                  variant="contained"
                  color="error"
                  onClick={() => {
                    confirm({ description: `Are you sure you want to do this?` })
                      .then(() => {
                        const loading = toast.loading('Revoking Certificate');
                        suspendCertificates(_id)
                          .then(() => {
                            setData([]);
                            setLoading(true);
                          })
                          .catch(() => toast.error('Error Revoking Certificate'))
                          .finally(() => toast.dismiss(loading));
                      })
                      .catch(() => console.log('Deletion cancelled.'));
                  }}
                >
                  Revoke
                </Button>
              )}
            </>
          );
        },
      },
    ];

    const multiselectCol: Column<Certificate> = {
      field: '_id',
      headerName: '',
      renderCell: (data) => {
        const { _id: value, suspended } = data.row as Certificate;
        const checked = selected.some((item) => item._id === value);

        return suspended ? (
          <></>
        ) : (
          <FormControlLabel
            onChange={(_, check) => {
              if (check) setSelected((prev) => [...prev, data.row]);
              else setSelected((prev) => prev.filter((item) => item._id !== value));
            }}
            checked={checked}
            control={<Checkbox color="primary" />}
            label=""
          />
        );
      },
    };

    return !disablFilter && multiselect ? [multiselectCol, ...col] : col;
  }, [disablFilter, multiselect, selected]);

  useEffect(() => {
    let filterdata = showrevoked ? data : data.filter((item) => !item.suspended);
    filterdata = filterdata.filter((item) => {
      return item.companyName.match(new RegExp(search, 'g'));
    });
    setFilterdata(filterdata);
  }, [data, showrevoked, search]);

  return (
    <section>
      {!disablHeader && (
        <div className="section-title">
          <div>Certificates</div>
          {extraHeaderContent}
        </div>
      )}
      {!disablFilter && (
        <div className="box-container mb-4 p-2 px-6  flex justify-between flex-wrap items-center">
          <div className="mr-4 flex-grow flex flex-wrap items-center">
            <TextField
              fullWidth
              size="small"
              placeholder="Search"
              onKeyUp={({ target }) => setSearch((target as HTMLInputElement).value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <i className="text-primary text-2xl ri-search-line" />
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div className="mr-8 flex items-center">
            <i className="icon ri-list-check-2 mr-2" /> FILTERS
          </div>

          <div className="mr-4">
            <FormControlLabel
              onChange={(_, value) => setMultiselect(value)}
              control={<Checkbox />}
              checked={multiselect}
              label="Select Multiple"
            />
          </div>

          <div className="mr-4">
            <FormControlLabel
              onChange={(_, value) => setShowrevoked(value)}
              checked={showrevoked}
              control={<Checkbox />}
              label="Show Revoked"
            />
          </div>

          {multiselect && (
            <>
              <div className="mr-2">
                <Button
                  disableElevation
                  variant="contained"
                  disabled={selected.length === 0}
                  onClick={() => {
                    const loading = toast.loading('Revoking Certificate');
                    selected.map(async (certificate) => {
                      return await suspendCertificates(certificate._id)
                        .then(() => setData([]))
                        .catch(() => toast.error('Error Revoking Certificate'))
                        .finally(() => toast.dismiss(loading));
                    });
                  }}
                >
                  Revoke Selected
                </Button>
              </div>

              <div className="mr-2">
                <Button
                  disableElevation
                  variant="contained"
                  disabled={selected.length === 0}
                  onClick={() => {
                    const loading = toast.loading('Generating Certificate');
                    selected.map(async (certificate) => {
                      return await downloadCertificates(certificate._id)
                        .catch(() => toast.error('Error Generating Certificate'))
                        .finally(() => toast.dismiss(loading));
                    });
                  }}
                >
                  Download Selected
                </Button>
              </div>
            </>
          )}
        </div>
      )}
      <div className="box-container">
        <DataGrid
          disableColumnMenu
          rows={filterdata}
          loading={loading}
          columns={columns}
          pageSize={DEFAULT_PAGE_SIZE}
          autoHeight={true}
          disableSelectionOnClick={true}
        />
      </div>
    </section>
  );
}
