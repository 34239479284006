import { Edit, Delete, VisibilityOutlined } from '@mui/icons-material';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE } from '../constant';
import { SUITS_URL, TYPE_URL } from '../routes';
import AddTestModel from '../section/AddTest';
import { getTests, Test, deleteTest } from '../service/Tests';
import { havePermission } from '../service/User';
import { Column } from '../types';
import { useConfirm } from 'material-ui-confirm';
// import { Test, addTest, updateTest } from '../service/Tests';

export default function TestsList() {
  const confirm = useConfirm();
  const { suitid, typeid } = useParams<{ suitid: string; typeid: string }>();
  const [tests, setTests] = useState<Test[]>([]);
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const [activeType, setActive] = useState<Test>();
  const statuses = ['InMind', 'Draft', 'Preliminary', 'Released', 'Deprecated'];

  function onDelete(test: Test) {
    toast.promise(deleteTest(test?._id), {
      loading: 'Loading',
      success: () => {
        setTests([]);
        setLoading(true);
        return `Successfully Deactivated`;
      },
      error: ({ response }: AxiosError) => response?.data.message ?? 'Some Error Occured',
    });
  }

  function onActive(company: Test) {
    // toast.promise(activatecompany(company), {
    //   loading: 'Loading',
    //   success: () => {
    //     setData([]);
    //     return `Successfully Activated`;
    //   },
    //   error: ({ response }: AxiosError) => response?.data.message ?? 'Some Error Occured',
    // });
  }
  function onEdit(test: Test) {
    setEdit(true);
    setActive(test);
  }
  useEffect(() => {
    if (!edit) {
      setActive(undefined);
    }
  }, [edit]);

  useEffect(() => {
    if (loading && tests.length === 0) {
      const loadingtoast = toast.loading('Loading Tests');
      getTests(typeid)
        .then((data) => setTests(data.tests))
        .catch(({ response }: AxiosError) =>
          toast.error(response?.data.message ?? 'Some Error Occured')
        )
        .finally(() => {
          toast.dismiss(loadingtoast);
          setLoading(false);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [tests, loading]);

  const columns: Column<Test>[] = useMemo(() => {
    const col: Column<Test>[] = [
      {
        flex: 1,
        field: 'title',
        headerName: 'Name',
      },
      { flex: 1, field: 'testRunOrder', headerName: 'Priority Order' },
      {
        field: 'certification_required',
        flex: 1,
        headerName: 'Mandatory For Certification',
        renderCell: (data) => {
          const { certification_required } = data.row as Test;
          return (
            <FormControlLabel
              onChange={(_, check) => {}}
              checked={certification_required}
              control={<Checkbox color="primary" />}
              label=""
            />
          );
        },
      },
      {
        flex: 1,
        headerName: 'Success Criteria',
        field: 'criteria',
        renderCell: (data) => {
          const { criteria } = data.row as Test;
          return <div className="upserCase"> {criteria} </div>;
        },
      },
      {
        flex: 1,
        headerName: 'Status',
        field: 'status',
        cellClassName: '',
        renderCell: (data) => {
          const { status } = data.row as Test;
          return (
            <div className={`upserCase ${status == 3 ? 'bg-green-300 p-2' : ''} `}>
              {statuses[status]}
            </div>
          );
        },
      },
    ];

    col.push({
      flex: 2,
      field: '',
      headerName: 'Actions',
      cellClassName: 'flex items-center',
      renderCell: (data) => {
        const { suspended } = data.row as Test;
        return (
          <>
            {havePermission(['psia-admin']) ? (
              <Button
                className="ml-2"
                disableElevation
                variant="contained"
                onClick={() => onEdit(data.row)}
              >
                <Edit className="mr-2" fontSize="small" /> Edit
              </Button>
            ) : (
              <Button
                className="ml-2"
                disableElevation
                variant="contained"
                onClick={() => onEdit(data.row)}
              >
                <VisibilityOutlined className="mr-2" fontSize="small" /> View
              </Button>
            )}

            {havePermission(['psia-admin']) ? (
              suspended ? (
                <Button
                  className="ml-2"
                  disableElevation
                  variant="contained"
                  color="error"
                  onClick={() => onActive(data.row)}
                >
                  <Delete className="mr-2" fontSize="small" /> Activate
                </Button>
              ) : (
                <Button
                  className="ml-2"
                  disableElevation
                  variant="contained"
                  color="error"
                  onClick={() => {
                    confirm({ description: `Are you sure you want to do this?` })
                      .then(() => {
                        onDelete(data.row);
                      })
                      .catch(() => console.log('Deletion cancelled.'));
                  }}
                >
                  <Delete className="mr-2" fontSize="small" /> Deactivate
                </Button>
              )
            ) : (
              <></>
            )}
          </>
        );
      },
    });

    return col;
  }, []);

  return (
    <section>
      <div className="section-title">
        <span>
          <Link to={SUITS_URL}>Suites &gt;&gt;</Link>{' '}
          <Link to={TYPE_URL.replace(':suitid', suitid)}> Profile</Link> &gt;&gt; Tests
        </span>
      </div>
      <div className="box-container mb-6 flex">
        <AddTestModel
          suitid={suitid}
          typeid={typeid}
          edit={edit}
          initialState={activeType}
          setActive={setActive}
          setEdit={setEdit}
          AfterSubmit={() => {
            setTests([]);
            setLoading(true);
          }}
        />
      </div>
      <div className="box-container">
        <DataGrid
          disableColumnMenu
          rows={tests.map((e) => ({ ...e, id: e._id }))}
          loading={loading}
          columns={columns}
          pageSize={DEFAULT_PAGE_SIZE}
          autoHeight={true}
          disableSelectionOnClick={true}
        />
      </div>
    </section>
  );
}
