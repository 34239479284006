import Sidebar from './components/dashboard/Sidebar';
import Header from './components/dashboard/Header';
import Footer from './components/dashboard/Footer';

import { Switch, Redirect } from 'react-router-dom';
import { PrivateRoute, PublicRoute } from './routing';
import NotFound from './view/NotFound';

import {
  APP_URL,
  CERTIFICATES_URL,
  DASHBOARD_URL,
  HISTORY_URL,
  USERS_URL,
  RESULTS_URL,
  RUN_TESTS_URL,
  SETTING_URL,
  SUITS_URL,
  TYPE_URL,
  TESTS_URL,
} from './routes';

import Dashboard from './view/Dashboard';
import Certificates from './view/Certificates';
import TestsResults from './view/TestsResults';
import History from './view/History';
import NewTest from './view/NewTest';
import Setting from './view/setting';
import Users from './view/Users';
import SuitsList from './view/SuitsList';
import TypesList from './view/TypesList';
import TestsList from './view/TestsList';

export default function Layout() {
  return (
    <div>
      <Header className="fixed top-0" />
      <div className="flex-grid h-screen overflow-y-scroll py-16">
        <Sidebar className="column w-32 sticky top-0" />
        <div className="column p-2">
          <Switch>
            <PrivateRoute path={DASHBOARD_URL} component={Dashboard} />

            <PrivateRoute exact path={RUN_TESTS_URL} component={NewTest} />

            <PrivateRoute path={RESULTS_URL} component={TestsResults} />
            <PrivateRoute path={USERS_URL} component={Users} />
            <PrivateRoute path={HISTORY_URL} component={History} />
            <PrivateRoute path={CERTIFICATES_URL} component={Certificates} />
            <PrivateRoute exact path={TESTS_URL} component={TestsList} />
            <PrivateRoute exact path={TYPE_URL} component={TypesList} />
            <PrivateRoute path={SUITS_URL} component={SuitsList} />

            <PrivateRoute path={SETTING_URL} component={Setting} />

            <Redirect exact from={APP_URL} to={DASHBOARD_URL} />
            <PublicRoute component={NotFound} />
          </Switch>
        </div>
        <Footer className="column w-full fixed bottom-0" />
      </div>
    </div>
  );
}
