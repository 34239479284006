import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { LatestTest, getTests } from '../service/DashboardMenu';
import { DEFAULT_IMAGE_URL, DEFAULT_PAGE_SIZE } from '../constant';
import { Button, Avatar } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { suspendCertificates } from '../service/Certificates';
import toast from 'react-hot-toast';
import { FormatDate } from '../utils/format';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import TestDetail from './TestDetail';
import { ResultResponse } from '../service/Tests';
import { useConfirm } from 'material-ui-confirm';

export default function CustomPaginationActionsTable() {
  const [data, setData] = useState<LatestTest[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage] = React.useState(DEFAULT_PAGE_SIZE);
  const [totalCount, setTotalCount] = React.useState(0);
  const [open, setOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(true);
  const [result, setResult] = useState<ResultResponse>();
  const confirm = useConfirm();
  useEffect(() => {
    if (data.length === 0 && open) {
      const loading = toast.loading('Fetching Results!');

      getTests(page, rowsPerPage)
        .then((dashboardData) => {
          const filterData = dashboardData.results.filter((item) => !item.suspended);
          setTotalCount(dashboardData.totalPages * rowsPerPage);
          setData(filterData);
          setOpen(false);
        })
        .catch(() => toast.error('Error Deleting Test Record'))
        .finally(() => {
          toast.dismiss(loading);
          setOpen(false);
        });
    }
  }, [data]);

  useEffect(() => {
    if (result) {
      setIsOpen(true);
    }
  }, [result]);

  function onDelete(test: LatestTest) {
    confirm({ description: `Are you sure you want to do this?` })
      .then(() => {
        const loading = toast.loading('Deleted Successfully!');
        suspendCertificates(test._id)
          .then(() => {
            setData([]);
          })
          .catch(() => toast.error('Error Deleting Test Record'))
          .finally(() => toast.dismiss(loading));
      })
      .catch(() => console.log('Deletion cancelled.'));
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalCount) : 0;

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
    setData([]);
  };

  return (
    <div>
      <div className="box-container flex justify-between">
        <TestDetail result={result} isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
      <div>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
          <TableHead>
            <TableRow className="heading">
              <TableCell className="heading-item">{'Date & Time '}</TableCell>
              <TableCell className="heading-item"> Suite Name</TableCell>
              <TableCell className="heading-item"> Company Name </TableCell>
              <TableCell className="heading-item" align="center">
                User
              </TableCell>
              <TableCell className="heading-item"> Action </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {!data ? (
              <CircularProgress color="inherit" />
            ) : (
              data.map((row) => (
                <CustomRow
                  row={row}
                  setIsOpen={setIsOpen}
                  onDelete={onDelete}
                  setResult={setResult}
                  key={row.id}
                />
              ))
            )}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
      />
    </div>
  );
}

function CustomRow(props: {
  row: LatestTest;
  onDelete: (server: any) => void;
  setIsOpen: (active: boolean) => void;
  setResult: (active: ResultResponse) => void;
}) {
  const { row, onDelete, setResult } = props;
  const [open, setOpen] = useState(false);
  return (
    <>
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} onClick={() => setOpen(!open)}>
          <TableCell className="row-item">{FormatDate(row.testPerformedDate)}</TableCell>
          <TableCell className="row-item">{row.suitName}</TableCell>
          <TableCell className="row-item">{row.companyName}</TableCell>
          <TableCell>
            <div className="flex items-center justify-center">
              <Avatar
                alt={row.userLastName}
                src={DEFAULT_IMAGE_URL + row.userProfileImage}
                className="mr-2"
              />
              {row.userLastName}
            </div>
          </TableCell>
          <TableCell>
            <Button
              className="ml-2"
              disableElevation
              variant="contained"
              color="error"
              onClick={() => onDelete(row)}
            >
              <Delete className="mr-2" fontSize="small" /> Delete
            </Button>
          </TableCell>
          <TableCell>
            <IconButton aria-label="expand row" size="small">
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Details
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow className="sub-heading">
                      <TableCell className="heading-item">{'Date & Time'}</TableCell>
                      <TableCell className="heading-item">Operation</TableCell>
                      <TableCell className="heading-item">Message</TableCell>
                      <TableCell className="heading-item">Result</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.tests.map((test, index) => {
                      if (test['response']) {
                        return (
                          <TableRow
                            sx={{ '& > *': { borderBottom: 'unset' } }}
                            key={`${test['id']}-${index}`}
                            onClick={() => {
                              setResult(test['response']);
                            }}
                          >
                            <TableCell className="row-item">
                              {FormatDate(test['response']['Timestamp'])}
                            </TableCell>
                            <TableCell className="row-item">
                              {test['response']['Operation']}
                            </TableCell>
                            <TableCell className="row-item">
                              {test['response']['Message']}
                            </TableCell>
                            <TableCell
                              className="row-item "
                              style={{ background: test['isPass'] ? 'green' : '#d33c30' }}
                            >
                              {test['isPass'] ? 'Pass' : 'Fail'}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    </>
  );
}
