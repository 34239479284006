import React, { useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import logo from '../../assets/logo.png';

import { SETTING_URL, SIGNIN_URL } from '../../routes';
import { havePermission, logout, useUserStore, closeAllProvisions } from '../../service/User';

import {
  ModalProps,
  Avatar,
  Popover,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import toast from 'react-hot-toast';
import { Logout, Settings, Close } from '@mui/icons-material';
import { DEFAULT_IMAGE_URL } from '../../constant';
import { useConfirm } from 'material-ui-confirm';
import { AxiosError } from 'axios';

export default function Header(props: { className: string }) {
  const { className } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement>();
  const user = useUserStore((state) => state.userInfo);
  const [profileImage, setProfileImage] = React.useState(DEFAULT_IMAGE_URL + user?.profileImage);

  useEffect(() => {
    if (user) {
      setProfileImage(DEFAULT_IMAGE_URL + user.profileImage);
    }
  }, [user]);

  return (
    <div
      className={`flex-grid justify-between items-center bg-white shadow-md w-full z-20 py-2 ${className}`}
    >
      <div className="column flex-none w-32 text-center">
        <img src={logo} alt="logo" className="inline-block" />
      </div>
      <div className="column flex-grid flex-initial items-center">
        {/* <div className="column1 pr-4">
          <Badge badgeContent={4} variant="dot" color="primary">
            <NotificationsNoneOutlined color="action" />
          </Badge>
        </div> */}
        <div
          className="column flex items-center"
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          <Avatar className="mr-2" alt={user?.firstName} src={profileImage}>
            {user?.firstName[0]} {user?.lastName[0]}
          </Avatar>
          <div className="text-gray-600">Howdy, {user?.firstName}</div>
          <i className="ri-arrow-down-s-fill" />
        </div>
      </div>
      <UserDropdown anchorEl={anchorEl} handleClose={() => setAnchorEl(undefined)} />
    </div>
  );
}

function UserDropdown(props: {
  anchorEl?: HTMLDivElement | undefined;
  handleClose?: ModalProps['onClose'];
}) {
  const { anchorEl, handleClose } = props;
  const history = useHistory();
  const confirm = useConfirm();

  const handleLogout = () => {
    logout();
    localStorage.clear();
    history.push(SIGNIN_URL);
  };

  const handleProvisions = () => {
    toast.loading('Terminating All Provisions');
    closeAllProvisions()
      .then((data) => {
        history.push('/app/dashboard');
        // toast.success(data.message);
      })
      .catch(({ response }: AxiosError) =>
        toast.error(response?.data.message ?? 'Some Error Occurred')
      )
      .finally(() => {
        toast.dismiss('');
      });
  };

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleClose}
      className="mt-2"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <List className="min-w-dropdown" component="nav" aria-label="main mailbox folders">
        <NavLink to={SETTING_URL}>
          <ListItemButton>
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText primary="Account" />
          </ListItemButton>
        </NavLink>
        {havePermission(['psia-admin']) && (
          <ListItemButton
            onClick={() => {
              confirm({ description: `Are you sure you want to close all Provisions?` })
                .then(() => {
                  handleProvisions();
                })
                .catch(() => console.log('Deletion cancelled.'));
            }}
          >
            <ListItemIcon>
              <Close color="action" />
            </ListItemIcon>

            <ListItemText primary="Close All Provisions" />
          </ListItemButton>
        )}

        <ListItemButton onClick={handleLogout}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>

          <ListItemText primary="Logout" />
        </ListItemButton>
      </List>
    </Popover>
  );
}
