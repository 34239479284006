import {
  SpeedOutlined,
  DescriptionOutlined,
  EqualizerOutlined,
  CardMembershipOutlined,
  PeopleOutlineOutlined,
} from '@mui/icons-material';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import {
  CERTIFICATES_URL,
  DASHBOARD_URL,
  USERS_URL,
  RUN_TESTS_URL,
  RESULTS_URL,
  SUITS_URL,
} from '../../routes';

import { NavLink } from 'react-router-dom';
import { havePermission } from '../../service/User';

interface Props {
  className?: string;
}

export default function Sidebar(props: Props) {
  const { className } = props;

  const SideBarData = [
    {
      title: 'Dashboard',
      icon: <SpeedOutlined />,
      path: DASHBOARD_URL,
      show: true,
    },
    {
      title: 'Run Test',
      icon: <DescriptionOutlined />,
      path: RUN_TESTS_URL,
      show: true,
    },
    {
      title: 'Test Results',
      icon: <EqualizerOutlined />,
      path: RESULTS_URL,
      show: true,
    },
    {
      title: 'Certificates',
      icon: <CardMembershipOutlined />,
      path: CERTIFICATES_URL,
      show: true,
    },
    {
      title: 'Users',
      icon: <PeopleOutlineOutlined />,
      path: USERS_URL,
      show: havePermission(['company-admin', 'psia-admin', 'psia-user']),
    },
    // {
    //   title: 'History',
    //   icon: <HistoryOutlined />,
    //   path: HISTORY_URL,
    //   show: true,
    // },
    {
      title: 'Scripts',
      icon: <NoteAltOutlinedIcon />,
      path: SUITS_URL,
      show: havePermission(['company-admin', 'psia-admin', 'psia-user']),
    },
  ];

  return (
    <div className={`bg-white border-r px-2 ${className}`}>
      <div className="hide-scrollbar overflow-scroll sticky top-1.5 text-gray-600">
        {SideBarData.filter((menu) => menu.show !== false).map((val, key) => (
          <NavLink
            key={key}
            to={val.path}
            className="w-full block outline-none text-center py-5 hover:bg-gray-300 rounded-lg mt-2"
            activeClassName="text-white bg-gray-700"
          >
            <span className="block w-full">{val.icon}</span>
            {/* <span className="block w-full">
              <img src="/icons/Group 10010.png" alt="" />
            </span> */}

            <span className="block text-sm">{val.title}</span>
          </NavLink>
        ))}
      </div>
    </div>
  );
}
