import { useEffect, useState } from 'react';
import { Modal, Button, FormControl, TextField, FormHelperText } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { addType, Type, updateType } from '../service/Tests';
import { AxiosError } from 'axios';
import { Link } from 'react-router-dom';
import { SUITS_URL } from '../routes';
import { havePermission } from '../service/User';

interface FormState {
  name: string;
}

interface Props {
  AfterSubmit?: (server: any) => void;
  suitId: string;
  edit: boolean;
  initialState?: Type;
  setEdit: (active: boolean) => void;
  setActive: (active: Type) => void;
}

export default function AddTypeModel(props: Props) {
  const { edit, setEdit, AfterSubmit, initialState: initialState, setActive, suitId } = props;
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('Add Suite');
  useEffect(() => {
    if (edit) setOpen(true);
  }, [edit]);

  useEffect(() => {
    reset(initialState);
    setTitle('Update Type');
  }, [initialState]);

  const { handleSubmit, control, reset } = useForm<FormState>({
    defaultValues: {
      name: '',
    },
  });

  async function OnSubmit(form: FormState) {
    if (initialState?._id) {
      toast.promise(updateType(initialState._id, suitId, form.name), {
        loading: 'Loading',
        success: () => {
          AfterSubmit?.(form);
          setOpen(false);
          setEdit(false);
          setActive({
            name: '',
            suspended: false,
            _id: '',
          });
          return `Successfully Updated!`;
        },
        error: ({ response }: AxiosError) => response?.data.message ?? 'Some Error Occured',
      });
    } else {
      toast.promise(addType(suitId, form.name), {
        loading: 'Loading',
        success: () => {
          AfterSubmit?.(form);
          setOpen(false);
          return `Successfully Added`;
        },
        error: ({ response }: AxiosError) => response?.data.message ?? 'Some Error Occured',
      });
    }
  }

  return (
    <>
      <div className="back-button flex justify-start column w-6/12 ">
        <Link to={SUITS_URL}>
          <Button variant="contained" disableElevation>
            Back
          </Button>
        </Link>
      </div>
      {havePermission(['psia-admin']) && (
        <div className="add-new flex justify-end column w-6/12">
          <Button variant="contained" disableElevation onClick={() => setOpen(true)}>
            Add New
          </Button>
        </div>
      )}

      <Modal
        className="outline-0 flex items-center justify-center"
        open={open}
        onClose={() => {
          setOpen(false);
          setEdit(false);
          setTitle('Add Type');
        }}
      >
        <section className="modal-container">
          <div className="section-title text-3xl"> {title}</div>
          <form className="mt-2" onSubmit={handleSubmit(OnSubmit)}>
            <FormControl fullWidth className="label label-small mr-2">
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <TextField
                      value={value ?? ''}
                      label="Type Name"
                      name={name}
                      onBlur={onBlur}
                      inputRef={ref}
                      onChange={onChange}
                      inputProps={{ maxLength: 60 }}
                      error={!!error}
                    />

                    <FormHelperText className="text-red-800">{error?.message}</FormHelperText>
                  </>
                )}
                name="name"
                control={control}
              />
            </FormControl>
            <div className="flex justify-center">
              <Button
                variant="contained"
                className="mt-2"
                disableElevation
                onClick={handleSubmit(OnSubmit)}
              >
                Save
              </Button>
            </div>
          </form>
        </section>
      </Modal>
    </>
  );
}
