import styled from 'styled-components';

export const RunTestTopBar = styled.div`
  background: #f6f6f6;
  width: 100%;
  display: flex;
  height: 70px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px 0px 15px;
  border-left: 1px solid #f6f6f6;
`;

export const RunTestSearchBar = styled.div`
  background: #f6f6f6;
  width: 100%;
  display: flex;
  height: 70px;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 30px 0px 15px;
  border-left: 1px solid #f6f6f6;
  select + select {
    margin-left: 10px;
  }
`;


export const CustomSelectBox = styled.select`
  border: none;
  outline: none;
  width: 20%;
  height: 44px;
  font-size: 19px;
  padding: 10px;
  option {
    border: none;
    outline: none;
    box-shadow: none;
  }
`;

export const SearchCheckBox = styled.label`
  outline: none;
  border: none;
  border-radius: 30px;
  display: flex;
  align-items: center;
  margin-left: 30px;
  svg {
    height: 30px;
    width: 30px;
  }

  option {
    &:hover {
      color: red;
      background: red;
      box-shadow: 0 0 10px 100px #1882a8 inset;
    }
  }
`;
