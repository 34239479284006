import create, { GetState, SetState } from 'zustand';
import {
  persist,
  subscribeWithSelector,
  StoreApiWithSubscribeWithSelector,
} from 'zustand/middleware';
import api, { Response } from '../utils/api';
import apiMultipart from '../utils/apiMulti';

export interface UserData {
  suspended: boolean; // false;
  _id: string; // '61cc2768b82ddb083cfbab49';
  firstName: string; // '';
  lastName: string; // '';
  phone?: string; // '';
  email: string; // '';
  image: string;
  company: string;
  roleSlug: Role['slug']; // 'basic';
  active: boolean; // false;
  profileImage?: string;
}

interface User {
  token: string; // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxY2MyNzY4YjgyZGRiMDgzY2ZiYWI0OSIsImxhc3ROYW1lIjoiTXVkYXNzYXIiLCJlbWFpbCI6Im11ZGFzc2FyLnphaGlkQHB1cmVsb2dpY3MubmV0IiwiaWF0IjoxNjQyNjA0MjM4LCJleHAiOjE2NDI2NDAyMzh9.YfRoTO3aOnNB4xS0OkhhJmgHBmeM4x5h1UrsfpJy32o';
  refreshToken: string; // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxY2MyNzY4YjgyZGRiMDgzY2ZiYWI0OSIsImxhc3ROYW1lIjoiTXVkYXNzYXIiLCJlbWFpbCI6Im11ZGFzc2FyLnphaGlkQHB1cmVsb2dpY3MubmV0IiwiaWF0IjoxNjQyNjA0MjM4LCJleHAiOjE2NDI2OTA2Mzh9.VrYc-2jjDjSWEInlmf-ecVwoxHUUUxEJVA9fIzx0k00';
  userInfo: UserData;
}
export enum RoleSlugName {
  'company-admin' = 'Company Admin',
  'company-user' = 'Company User',
  'psia-admin' = 'Psia Admin',
  'psia-user' = 'Psia User',
  'super-admin' = 'Super Admin',
}

export interface Role {
  _id: string; // "61a797a15e9aec5c48b16811",
  slug: keyof typeof RoleSlugName; // "company-admin",
  name: typeof RoleSlugName[Role['slug']]; // "Company Admin",
  status: boolean; // true
}

export interface TerminateAllProvisions {
  message: String;
  totalTerminated?: Number;
  code?: Number;
}

interface UserStoreState extends Partial<User> {
  expired: boolean;
}

export const useUserStore = create(
  persist<
    UserStoreState,
    SetState<UserStoreState>,
    GetState<UserStoreState>,
    StoreApiWithSubscribeWithSelector<UserStoreState>
  >(
    subscribeWithSelector(() => ({
      expired: Boolean('true'),
    })),
    {
      name: 'UserStore',
      getStorage: () => localStorage,
    }
  )
);

export async function login(pram: { email: string; password: string }) {
  const { data: response } = await api.post<Response<User>>('/auth/login', pram);
  useUserStore.setState({ ...response.data, expired: false });
}

export function havePermission(roles: Role['slug'][]) {
  const user = useUserStore.getState().userInfo;
  if (!user) return false;
  const resp = user.roleSlug === 'super-admin' || roles.includes(user.roleSlug);
  return resp;
}

export function logout() {
  useUserStore.setState({ userInfo: undefined, expired: true }, true);
}

export async function getUser(id: number) {
  const { data: response } = await api.get<Response<UserData>>(`/users/${id}`);
  return response.data;
}

export async function createUser(data: UserData) {
  const { data: response } = await api.post<UserData>(`/users`, data);
  return response;
}

export async function updateUser(data: UserData) {
  const { data: response } = await api.put<UserData>(`/users/${data._id}`, data);

  return response;
}

export async function updateUserProfile(data: UserData) {
  const { data: response } = await api.put<Response<UserData>>(`/users/${data._id}`, data);
  useUserStore.setState({ userInfo: data, expired: false });
  return response;
}
export async function updateProfileImage(data: any, _id: string) {
  const { data: response } = await apiMultipart.put<Response<UserData>>(`/users/${_id}`, data);
  useUserStore.setState({ userInfo: response.data, expired: false });
  return response;
}

export async function deleteUser(data: UserData) {
  const { data: response } = await api.get<UserData>(`/users/suspend/${data._id}`);
  return response;
}

export async function activateUser(data: UserData) {
  const { data: response } = await api.get<UserData>(`/users/unsuspend/${data._id}`);
  return response;
}

export async function getUsers() {
  const { data: response } = await api.get<Response<UserData[]>>(`/users`);
  return response.data;
}
export async function getRoles() {
  const { data: response } = await api.get<Response<Role[]>>(`/roles`);
  return response.data;
}

export async function closeAllProvisions() {
  const { data: response } = await api.get<Response<TerminateAllProvisions>>(
    `/engine/terminateAllProvisions`
  );
  return response.data;
}
