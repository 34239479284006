import { Button } from '@mui/material';

// import stopBtn from '../assets/images/svgs/stop_circle.svg';
// import playBtn from '../assets/images/svgs/play_circle.svg';
// import pauseBtn from '../assets/images/svgs/pause_circle.svg';
// import forwardBtn from '../assets/images/svgs/fast_forward.svg';

import { TestPayload, useTestsStore } from '../service/Tests';
import ConnectionModel from './ConnectionModel';
import { useServerStore } from '../service/Server';
import { useAdapterStore } from '../service/Adapter';

export default function TestRunner(props: {
  expand: boolean;
  onBack: () => void;
  field: TestPayload[];
  onStart: () => void;
}) {
  const { field, onStart, onBack, expand } = props;
  const running = useTestsStore((state) => state.running);
  const adapterstatus = useAdapterStore((state) => state.status);
  const connected = useServerStore(
    ({ _id, availableIp }) => !!_id && !!availableIp && !!adapterstatus
  );
  // console.log('---------running', running);

  return (
    <div className="box-container p-4">
      <div className="flex items-center justify-between">
        {expand ? (
          <Button variant="contained" color="error" disableElevation onClick={onBack}>
            End Test
          </Button>
        ) : (
          <div></div>
        )}
        <div className="flex items-center">
          <div className="mr-2">
            <Button
              variant="contained"
              color="warning"
              disableElevation
              onClick={onStart}
              disabled={!field.length || !connected || running}
            >
              {expand ? 'Restart' : 'Run'}
            </Button>
          </div>

          <div className="mr-2">
            <ConnectionModel />
          </div>
        </div>
      </div>
    </div>
  );
}
