import { AxiosError } from 'axios';
import api, { Response } from '../utils/api';
import { FormatDate } from '../utils/format';
import { Criteria, Type } from './Tests';
import { saveAs } from 'file-saver';

export interface Certificate {
  suspended: boolean;
  userProfileImage: string;
  tests: [
    {
      id: string;
      title: 'Create Sample Data';
      criteria: Criteria;
      testId: string;
      certification_required: boolean;
      validForCertification: boolean;
      type: Type['_id'];
      userId: string;
      companyId: string;
      isPass: boolean;
      adapterUrl: string;
      username: string;
      password: string;
      issuerSignature: string;
      provisionIp: string;
      response: {
        Operation: string;
        Message: string;
        Status: string;
        Timestamp: string;
        Data: [
          {
            uid: string;
            payload_data: string;
          }
        ];
      };
      suitname: string;
      testCreatedAt: string;
      payload: {
        need_records: number;
        name: string;
        testId: string;
      };
    }
  ];
  _id: string;
  suitName: string;
  type: string;
  adapterUrl: string;
  username: string;
  password: string;
  issuerSignature: string;
  provisionIp: string;
  id: string;
  totalTest: number;
  totalPassed: number;
  totalMandatoryToPassed: number;
  testPerformedDate: string;
  userEmail: string;
  userLastName: string;
  userId: string;
  companyName: string;
}

export async function getCertificates(): Promise<Certificate[]> {
  const { data: response } = await api
    .get<Response<Certificate[]>>(`/certificate/getValidCertificates`)
    .catch((error: AxiosError) => {
      throw error;
    });

  return response.data.map((certificate) => ({
    ...certificate,
    testPerformedDate: FormatDate(certificate.testPerformedDate),
  }));
}

export async function downloadCertificates(id: string): Promise<any> {
  const { data: response } = await api
    .get<Blob>(`/tests/certificate/${id}`, {
      headers: { Accept: 'application/pdf' },
      responseType: 'blob',
    })
    .catch((error: AxiosError) => {
      throw error;
    });

  saveAs(response, `Certificate ${id}.pdf`);

  return response;
}
export async function suspendCertificates(id: string): Promise<any> {
  const { data: response } = await api
    .get<Response<Certificate[]>>(`/certificate/suspend/${id}`)
    .catch((error: AxiosError) => {
      throw error;
    });
  return response;
}
