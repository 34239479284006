import {
  FormControl,
  InputLabel,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  TextField,
  FormHelperText,
  Chip,
  CircularProgress,
} from '@mui/material';

import { TestPayload, TestStatus, useTestsStore } from '../service/Tests';
import { Controller } from 'react-hook-form';
import { memo, useEffect, useState } from 'react';
import { Done, ExpandMore, Cancel } from '@material-ui/icons';

interface Props {
  errors: { [x: string]: any };
  testindex: number;
  test: TestPayload;
  control: any;
  isDataScript?: boolean;
}

export const Chips = (status: TestStatus, text: string | undefined = undefined) => {
  const chip: Record<TestStatus, JSX.Element> = {
    Inprogress: (
      <Chip
        className="inline-flex"
        label={text ?? 'Inprogress'}
        icon={<CircularProgress size="1.5rem" />}
      />
    ),
    Success: (
      <Chip className="inline-flex" label={text ?? 'Success'} color="success" icon={<Done />} />
    ),
    Fail: (
      <Chip
        className="inline-flex"
        label={text ?? 'Error'}
        color={text === 'Fail' || text === 'Success' ? 'error' : 'warning'}
        icon={<Cancel />}
      />
    ),
    Preparing: (
      <Chip
        className="inline-flex"
        label={text ?? 'Preparing'}
        icon={<CircularProgress size="1.5rem" />}
      />
    ),
    Queue: (
      <Chip
        className="inline-flex"
        label={text ?? 'Queue'}
        icon={<CircularProgress size="1.5rem" />}
      />
    ),
  };

  return chip[status];
};

function TestField(props: Props) {
  const { isDataScript, errors, testindex, test, control } = props;
  const [expanded, setExpanded] = useState(false);

  const recenttest = useTestsStore((state) => state.recenttest);
  const testrunning = useTestsStore((state) => state.running);
  const error = !!Object.values(errors).length;
  const scriptResult = useTestsStore((state) => state.scriptResult);
  function getChip() {
    if (recenttest?.status === 'Queue') return Chips('Queue');

    const allresults = recenttest?.result;

    if (allresults) {
      const testresult = allresults[test._id];

      if (testresult) {
        const result = testresult.responseData[0];
        if (result)
          return Chips(
            result.Status.toLowerCase() === test.criteria.toLowerCase() ? 'Success' : 'Fail',
            result.Status
          );
      } else if (testrunning === true) return Chips('Inprogress');
      else return Chips('Fail');
    }
  }
  function getValue(dependOnTestId: string, key: any) {
    if (!dependOnTestId) return undefined;
    if (scriptResult) {
      if (!scriptResult[dependOnTestId]) return undefined;
      const testresult = scriptResult[dependOnTestId].responseData[0];
      return testresult[key].replace(/'|"|{|}/g, '');
    }
  }

  return (
    <Accordion
      expanded={error ? error : testrunning ? !testrunning : expanded}
      elevation={2}
      className="rounded-none shadow border-t"
    >
      <AccordionSummary
        onClick={() => setExpanded(!expanded)}
        className={`transition-colors duration-700 flex items-center justify-between ${
          errors?.tests && errors.tests[testindex] ? 'bg-red-400' : ''
        }`}
        expandIcon={<ExpandMore />}
      >
        {test.title} {!isDataScript ? getChip() : ''}
      </AccordionSummary>
      <AccordionDetails>
        <div className="mb-4">
          <InputLabel>Script</InputLabel>
          <TextField
            disabled={true}
            className="text-gray-800"
            fullWidth
            multiline
            rows={4}
            value={test.script}
          />
        </div>
        <div className="flex-grid no-space">
          {test.fields.map(({ key, type, value, required, dependOnTestId }, fieldindex) => (
            <div key={key} className="w-full">
              <FormControl fullWidth className="label label-small">
                <Controller
                  defaultValue={value || ''}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { error },
                  }) => (
                    <>
                      <TextField
                        size="small"
                        type={type}
                        value={
                          key == 'need_records'
                            ? 1
                            : getValue(dependOnTestId, key)
                            ? getValue(dependOnTestId, key)
                            : value
                        }
                        label={key}
                        name={name}
                        // disabled={key == 'need_records' ? true : false}
                        onBlur={onBlur}
                        inputRef={ref}
                        onChange={onChange}
                        error={!!error}
                        {...(required && { required: true })}
                      />

                      <FormHelperText className="text-red-800">{error?.message}</FormHelperText>
                    </>
                  )}
                  name={`tests.${testindex}.fields.${fieldindex}.value`}
                  control={control}
                  rules={
                    {
                      // ...(required && { required: 'Field is required' }),
                    }
                  }
                />
              </FormControl>
            </div>
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default memo(TestField);
