import { ThemeProvider } from 'styled-components';
import '@mui/styles';
import { Router, Switch, Redirect } from 'react-router-dom';

import { PublicRoute, PrivateRoute } from './routing';
import { APP_URL, SIGNIN_URL } from './routes';

import NotFound from './view/NotFound';
import Signin from './view/Signin';
import Layout from './Layout';
import { history } from './utils/api';
import { ConfirmProvider } from 'material-ui-confirm';

const theme = {
  colors: {
    header: '#ebfbff',
    footer: '#003333',
  },
  mobile: '768px',
  SideBarWidth: {
    logo: '100px',
    menuWidth: '100px',
    fontSize: '10px',
  },
};

function App() {
  return (
    <ConfirmProvider>
      <ThemeProvider theme={theme}>
        <div className="App bg-gray-200 text-gray-700">
          <Router history={history}>
            <Switch>
              <PublicRoute exact path={SIGNIN_URL} component={Signin} />

              <PrivateRoute path={APP_URL} component={Layout} />
              <Redirect exact from="/" to={APP_URL} />
              <PublicRoute component={NotFound} />
            </Switch>
          </Router>
        </div>
      </ThemeProvider>
    </ConfirmProvider>
  );
}

export default App;
