import { Route, Redirect } from 'react-router-dom';
import { useUserStore } from './service/User';

export const PrivateRoute = (props: any) => {
  const { component: Component, ...rest } = props;
  const { expired, userInfo } = useUserStore((state) => state);
  const auth = userInfo && !expired;

  if (!auth)
    return (
      <Route
        {...rest}
        render={(routeProps) => (
          <Redirect to={{ pathname: '/signin', state: { from: routeProps.location } }} />
        )}
      />
    );

  return <Route {...rest} render={(routeProps) => <Component {...routeProps} />} />;
};

export const PublicRoute = (props: any) => {
  const { component: Component, ...rest } = props;

  return <Route {...rest} render={(routeProps) => <Component {...routeProps} />} />;
};
