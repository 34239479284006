export function FormatDate(str: string) {
  const date = new Date(str);
  return date.toLocaleString('en-US', {
    year: 'numeric',
    day: 'numeric',
    month: 'short',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
}
