// import { useUserStore, UserData } from '../../service/User';
import { Controller, useForm } from 'react-hook-form';
import { Avatar, Button, FormControl, FormHelperText, TextField } from '@mui/material';
import { useState } from 'react';
import toast from 'react-hot-toast';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useUserStore, UserData, updateUserProfile, updateProfileImage } from '../../service/User';
import { AxiosError } from 'axios';
import { DEFAULT_IMAGE_URL } from '../../constant';
import { useHistory } from 'react-router-dom';

interface FormState extends UserData {
  password: string;
  newPassword: string;
}

export default function Account() {
  const user = useUserStore((state) => state.userInfo);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [profileImg, setProfileImg] = useState(DEFAULT_IMAGE_URL + user?.profileImage);
  const history = useHistory();

  const { handleSubmit, control, getValues } = useForm<FormState>({
    defaultValues: user,
  });
  const [editting, setEditting] = useState({
    name: false,
    email: false,
    password: false,
  });

  function Submit(value: FormState) {
    delete value['phone'];
    toast.promise(updateUserProfile(value), {
      loading: 'Loading',
      success: () => {
        setEditting({
          name: false,
          email: false,
          password: false,
        });
        return `Successfully Updated'}`;
      },
      error: ({ response }: AxiosError) => response?.data.message ?? 'Some Error Occured',
    });
  }

  function UploadFile(value: FormState) {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('profileImage', selectedFile, selectedFile.name);
      toast.promise(updateProfileImage(formData, value._id), {
        loading: 'Loading',
        success: () => {
          return '';
        },
        error: ({ response }: AxiosError) => response?.data.message ?? 'Some Error Occured',
      });
    }
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    setProfileImg(URL.createObjectURL(fileList[0]));
    setSelectedFile(fileList[0]);
  };
  const clearImage = () => {
    setProfileImg(DEFAULT_IMAGE_URL + user?.profileImage);
    setSelectedFile(undefined);
    history.push('/app/dashboard');
  };

  return (
    <div className="h-full">
      <section>
        <div className="section-title">Account</div>
        <div className="box-container">
          <form className="p-10 w-10/12 mx-auto" onSubmit={handleSubmit(Submit)}>
            <div className="label mb-10 flex flex-wrap justify-center items-center">
              <div className="column flex-grow-0 flex justify-center">
                <div className="rounded-full relative inline-flex">
                  <Avatar className="mr-2 w-56 h-56 text-8xl" src={profileImg}>
                    {user?.firstName[0]}
                    {user?.lastName[0]}
                  </Avatar>
                  <div className="text-4xl absolute right-0 bottom-0 bg-gray-500 text-white rounded-full p-3 inline-flex">
                    <Button component="label">
                      <i className="ri-image-fill" />
                      <input type="file" name="profileImage" onChange={(e) => onChange(e)} hidden />
                    </Button>
                  </div>
                </div>
              </div>
              <div className="column flex-grow-0 whitespace-nowrap">
                <div className="mb-2 text-4xl">
                  {!editting.name && (
                    <>
                      {user?.firstName} {user?.lastName}
                      <i
                        onClick={() => setEditting((prev) => ({ ...prev, name: true }))}
                        className="ri-pencil-fill ml-2"
                      />
                    </>
                  )}
                  {editting.name && (
                    <div className="flex items-center">
                      <FormControl className="mr-2">
                        <Controller
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { error },
                          }) => (
                            <>
                              <TextField
                                size="small"
                                value={value}
                                label="First Name"
                                name={name}
                                onBlur={onBlur}
                                inputRef={ref}
                                onChange={onChange}
                                error={!!error}
                              />

                              <FormHelperText className="text-red-800">
                                {error?.message}
                              </FormHelperText>
                            </>
                          )}
                          name="firstName"
                          control={control}
                          rules={{ required: 'Field is required' }}
                        />
                      </FormControl>

                      <FormControl className="mr-2">
                        <Controller
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { error },
                          }) => (
                            <>
                              <TextField
                                size="small"
                                value={value}
                                label="Last Name"
                                name={name}
                                onBlur={onBlur}
                                inputRef={ref}
                                onChange={onChange}
                                error={!!error}
                              />

                              <FormHelperText className="text-red-800">
                                {error?.message}
                              </FormHelperText>
                            </>
                          )}
                          name="lastName"
                          control={control}
                          rules={{ required: 'Field is required' }}
                        />
                      </FormControl>
                      <Button
                        className="mr-2"
                        disableElevation
                        variant="contained"
                        color="error"
                        onClick={() => setEditting((prev) => ({ ...prev, name: false }))}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="mr-2"
                        disableElevation
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit(Submit)}
                      >
                        Update
                      </Button>
                    </div>
                  )}
                </div>
                <div className="mb-4 text-xl text-primary">
                  {!editting.email && (
                    <>
                      {user?.email}
                      <i
                        className="ri-pencil-fill ml-2"
                        onClick={() => setEditting((prev) => ({ ...prev, email: true }))}
                      />
                    </>
                  )}
                  {editting.email && (
                    <div className="flex items-center">
                      <FormControl className="mr-2">
                        <Controller
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { error },
                          }) => (
                            <>
                              <TextField
                                size="small"
                                value={value}
                                label="Email"
                                name={name}
                                onBlur={onBlur}
                                inputRef={ref}
                                onChange={onChange}
                                error={!!error}
                              />

                              <FormHelperText className="text-red-800">
                                {error?.message}
                              </FormHelperText>
                            </>
                          )}
                          name="email"
                          control={control}
                          rules={{
                            required: 'Field is required',
                          }}
                        />
                      </FormControl>
                      <Button
                        className="mr-2"
                        disableElevation
                        variant="contained"
                        color="error"
                        onClick={() => setEditting((prev) => ({ ...prev, email: false }))}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="mr-2"
                        disableElevation
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit(Submit)}
                      >
                        Update
                      </Button>
                    </div>
                  )}
                </div>

                {!editting.password && (
                  <Button
                    disableElevation
                    variant="contained"
                    color="primary"
                    onClick={() => setEditting((prev) => ({ ...prev, password: true }))}
                  >
                    Change Password
                  </Button>
                )}

                {editting.password && (
                  <div className="flex items-center w-full">
                    <Tooltip
                      title=" Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
                      placement="bottom"
                    >
                      <InfoIcon />
                    </Tooltip>
                    <FormControl className="mr-2 ">
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { error },
                        }) => (
                          <>
                            <TextField
                              size="small"
                              type="password"
                              value={value}
                              label="Password"
                              name={name}
                              onBlur={onBlur}
                              inputRef={ref}
                              onChange={onChange}
                              error={!!error}
                            />

                            <FormHelperText className="text-red-800">
                              <span style={{ width: '' }}>{error?.message}</span>
                            </FormHelperText>
                          </>
                        )}
                        name="password"
                        control={control}
                        rules={{
                          required: 'Field is required',
                          pattern: {
                            // eslint-disable-next-line  no-useless-escape
                            value:
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                            message: 'Chose a Strong Password',
                          },
                        }}
                      />
                    </FormControl>

                    <FormControl className="mr-2">
                      <Controller
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { error },
                        }) => (
                          <>
                            <TextField
                              size="small"
                              value={value}
                              type="password"
                              label="Confirm Password"
                              name={name}
                              onBlur={onBlur}
                              inputRef={ref}
                              onChange={onChange}
                              error={!!error}
                            />

                            <FormHelperText className="text-red-800">
                              {error?.message}
                            </FormHelperText>
                          </>
                        )}
                        name="newPassword"
                        control={control}
                        rules={{
                          required: 'Field is required',
                          validate: {
                            passwordEqual: (value) =>
                              value === getValues().password || 'Passwords do not match!',
                          },
                        }}
                      />
                    </FormControl>
                    <Button
                      className="mr-2"
                      disableElevation
                      variant="contained"
                      color="error"
                      onClick={() => setEditting((prev) => ({ ...prev, password: false }))}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="mr-2 "
                      disableElevation
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit(Submit)}
                    >
                      Update
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className="label flex justify-center">
              <Button
                disableElevation
                size="large"
                className="mx-2 "
                variant="contained"
                color="error"
                onClick={() => clearImage()}
              >
                Cancel
              </Button>
              <Button
                disableElevation
                size="large"
                className="mx-2"
                variant="contained"
                color="primary"
                onClick={handleSubmit(UploadFile)}
              >
                Save
              </Button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}
