import { AxiosError } from 'axios';
import create, { GetState, SetState, StoreApi } from 'zustand';
import { persist } from 'zustand/middleware';
import api, { Response } from '../utils/api';
import { useServerStore } from './Server';

type Protocol = 'HTTP' | 'HTTPS';

interface ProfileRequest extends Omit<Profile, '_id' | 'createdAt' | 'updatedAt' | 'userId'> {}

export interface Profile {
  _id?: string; //'61deb772b4bf1a2bea3d6d2e';
  protocol: Protocol; //'https';
  port: number; // 8080
  adapterUrl: string; //'https://sandbox2.kastle.com/';
  issuerSignature: string; //'67273125dc2607c232c719f3f1bda7f06a22698bd9ad2ba86920a4968937d0c8';
  username: string; //'PLIdentityUser@gl.com';
  password: string; //'password1234';
  createdAt: string; //'2022-01-12T11:11:46.487Z';
  updatedAt: string; //'2022-01-13T20:14:25.551Z';
  userId: string; //'61cc2768b82ddb083cfbab49';
}

export interface Adapter {
  operation: string; // 'Check Connectivity with Adapter';
  message: string; // 'Connection with the Adapter have been established successfully!';
  status: string; // 'success';
  timestamp: string; // '19-December-2021 18:14:18';
  data: { payload_data: string }[];
}

interface AdapterStoreState extends Partial<Adapter> {
  request?: ProfileRequest;
  response?: string;
}

export const useAdapterStore = create(
  persist<
    AdapterStoreState,
    SetState<AdapterStoreState>,
    GetState<AdapterStoreState>,
    StoreApi<AdapterStoreState>
  >(
    () => ({
      //  // // ================== Adapter
      // operation: 'Check Connectivity with Adapter',
      // message: 'Connection with the Adapter have been established successfully!',
      // status: 'success',
      // timestamp: '19-December-2021 18:14:18',
      // data: [{ payload_data: 'string' }],
    }),
    {
      name: 'AdapterStore',
      getStorage: () => localStorage,
    }
  )
);

export async function CheckAdapter(request: ProfileRequest) {
  const instanceID = useServerStore.getState()._id;
  useAdapterStore.setState({
    request: request,
  });
  const { data } = await api
    .post<Response<Adapter>>(`engine/adapter/${instanceID}`, request)
    .catch((error: AxiosError<Response<Adapter>>) => {
      useAdapterStore.setState({
        response: JSON.stringify(error.response?.data, null, 2),
      });
      throw error;
    });

  useAdapterStore.setState({
    ...data.data,
    response: JSON.stringify(data.data, null, 2),
  });
  return data;
}

export async function DisconnectAdapter(request: ProfileRequest) {
  const instanceID = useServerStore.getState()._id;
  useAdapterStore.setState({
    request: request,
  });
  const { data } = await api
    .put<Response<Adapter>>(`engine/adapter/${instanceID}`, request)
    .catch((error: AxiosError<Response<Adapter>>) => {
      useAdapterStore.setState({
        response: JSON.stringify(error.response?.data, null, 2),
      });
      throw error;
    });

  useAdapterStore.setState({}, true);
  return data;
}

export async function GetAdapterProfiles() {
  const { data } = await api.get<Response<Profile[]>>(`adapterProfile`);
  return data;
}
