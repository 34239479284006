import { LinearProgress } from '@mui/material';
import React, { useEffect } from 'react';

const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

interface Props {
  speed?: number;
  tickle?: boolean;
  tickleSpeed?: number;
  maxtickle?: number;
  running: boolean;
}

export default function ProgressBar(props: Props) {
  const { running, speed = 25, tickle = true, maxtickle = 0.95, tickleSpeed = 1500 } = props;

  const [progress, setProgress] = React.useState(0);
  const progressRef = React.useRef<any>();

  async function tick(newprogress: number) {
    return await new Promise<number>((res) => {
      if (!running) clearInterval(progressRef.current);
      if (newprogress > 1) {
        return newprogress;
      } else {
        newprogress += (maxtickle - newprogress) / speed;
        res(clamp(newprogress, 0, maxtickle));
      }
    });
  }

  useEffect(() => {
    let newprogress = progress;
    if (running && tickle) {
      const timeoutfunction = setInterval(async () => {
        newprogress = await tick(newprogress);
        setProgress(newprogress * 100);
      }, tickleSpeed);

      progressRef.current = timeoutfunction;
    } else if (progressRef.current) {
      clearInterval(progressRef.current);
    }

    if (newprogress > 0 && !running) {
      clearInterval(progressRef.current);
      setProgress(100);
    }
    return () => {
      clearInterval(progressRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [running, tickle, tickleSpeed]);

  return (
    <div>
      <LinearProgress
        className="absolute top-0 left-0 right-0"
        variant="determinate"
        value={progress}
        // valueBuffer={progress}
      />
    </div>
  );
}
