import loginLogo from '../assets/images/auth/log_logo.png';
import { Link, Redirect } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { login, useUserStore } from '../service/User';
import { APP_URL } from '../routes';
import toast from 'react-hot-toast';
import { AxiosError } from 'axios';
import { useState } from 'react';

interface FormState {
  email: string;
  password: string;
}

export default function Signin() {
  const { expired, userInfo } = useUserStore((state) => state);
  const auth = userInfo && !expired;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormState>({
    defaultValues: { email: '', password: '' },
  });
  const [passwordshow, setPasswordshow] = useState<boolean>(false);

  async function Submit(form: FormState) {
    toast.promise(login(form), {
      loading: 'Loading',
      success: () => {
        return `Successfully Signin`;
      },
      error: ({ response }: AxiosError) => response?.data.message.message ?? 'Some Error Occured',
    });
  }

  if (auth) return <Redirect to={APP_URL} />;

  return (
    <div className="flex items-center	justify-center bg-gray-300 w-full h-screen">
      <div className="rounded-2xl bg-white p-10 overflow-hidden max-w-90% md:max-w-sm">
        <img src={loginLogo} className="img mb-4" alt="logo" />

        <form onSubmit={handleSubmit(Submit)}>
          <label className="label">
            <input
              className={!errors.email ? 'input' : 'input has-error'}
              placeholder="Email"
              type="email"
              {...register('email', {
                required: { value: true, message: 'Email is required' },
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                  message: 'Please Enter valid Email',
                },
              })}
            />
            {errors.email && <div className="text-red-600 pl-1">{errors.email.message}</div>}
          </label>

          <label className="label">
            <span className="relative">
              <input
                className={!errors.password ? 'input pr-7' : 'input pr-7 has-error'}
                placeholder="Password"
                type={passwordshow ? 'text' : 'password'}
                {...register('password', {
                  required: { value: true, message: 'Password is required' },
                  minLength: { value: 4, message: 'Password Should be more than 4 length' },
                })}
              />
              <button
                type="button"
                onClick={() => setPasswordshow(!passwordshow)}
                className="absolute top-2/4 right-0 transform -translate-y-2/4 mr-2"
              >
                <i className={passwordshow ? 'ri-eye-line' : 'ri-eye-off-line'} />
              </button>
            </span>
            {errors.password && <div className="text-red-600 pl-1">{errors.password.message}</div>}
          </label>

          <Link to="/" className="text-center no-underline clr-primary block my-8">
            Forgot Password?
          </Link>

          <button type="submit" className="button font-bold">
            LOGIN
          </button>

          <div className="mt-6 text-sm text-primary opacity-70 text-center">
            ©2021 PLAI Test Tool. All Rights Reserved.
          </div>
        </form>
      </div>
    </div>
  );
}
