export const APP_URL = '/app';
export const SIGNIN_URL = '/signin';
export const SETTING_URL = `${APP_URL}/setting`;
export const ACCOUNT_URL = `${SETTING_URL}/account`;

//
// App Routes
//
export const DASHBOARD_URL = `${APP_URL}/dashboard`;

// tests Routes
export const RUN_TESTS_URL = `${APP_URL}/test`;

// monitors Routes
export const USERS_URL = `${APP_URL}/users`;

// results Routes
export const RESULTS_URL = `${APP_URL}/results`;

// history Routes
export const HISTORY_URL = `${APP_URL}/history`;

// scripts Routes
export const SUITS_URL = `${APP_URL}/scripts`;
export const TYPE_URL = `${SUITS_URL}/:suitid`;
export const TESTS_URL = `${TYPE_URL}/:typeid`;

// certificates Routes
export const CERTIFICATES_URL = `${APP_URL}/certificates`;
