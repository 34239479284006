import { Button, FormControl, FormHelperText, TextField } from '@mui/material';
import { AxiosError } from 'axios';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { addcompany, Company, updatecompany } from '../../service/Company';

interface Props {
  initialValue?: Partial<Company>;
  onCancel?: () => void;
  onSubmit?: (value: Company) => void;
}

export default function AddCompany(props: Props) {
  const { initialValue, onCancel, onSubmit: AfterSubmit } = props;

  const { handleSubmit, control } = useForm<Company>({ defaultValues: initialValue });

  function onSubmit(form: Company) {
    const create = !Object.keys(initialValue ?? {}).length;

    toast.promise(create ? addcompany(form) : updatecompany(form), {
      loading: 'Loading',
      success: () => {
        AfterSubmit?.(form);
        return `Successfully ${create ? 'Created' : 'Updated'}`;
      },
      error: ({ response }: AxiosError) => response?.data.message ?? 'Some Error Occured',
    });
  }

  return (
    <div className="box-container">
      <form className="w-10/12 mx-auto flex-grid">
        <div className="column w-6/12 mt-4">
          <FormControl fullWidth className="label">
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error },
              }) => (
                <>
                  <TextField
                    size="small"
                    value={value}
                    label="Company Name"
                    name={name}
                    onBlur={onBlur}
                    inputRef={ref}
                    onChange={onChange}
                    error={!!error}
                  />

                  <FormHelperText className="text-red-800">{error?.message}</FormHelperText>
                </>
              )}
              name="name"
              control={control}
              rules={{ required: 'Field is required' }}
            />
          </FormControl>
        </div>
        <div className="column w-6/12 mt-4">
          <FormControl fullWidth className="label">
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error },
              }) => (
                <>
                  <TextField
                    size="small"
                    value={value}
                    label="Country"
                    name={name}
                    onBlur={onBlur}
                    inputRef={ref}
                    onChange={onChange}
                    error={!!error}
                  />

                  <FormHelperText className="text-red-800">{error?.message}</FormHelperText>
                </>
              )}
              name="country"
              control={control}
              // rules={{ required: 'Field is required' }}
            />
          </FormControl>
        </div>
        <div className="column w-6/12 mt-4">
          <FormControl fullWidth className="label">
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error },
              }) => (
                <>
                  <TextField
                    size="small"
                    value={value}
                    label="State"
                    name={name}
                    onBlur={onBlur}
                    inputRef={ref}
                    onChange={onChange}
                    error={!!error}
                  />

                  <FormHelperText className="text-red-800">{error?.message}</FormHelperText>
                </>
              )}
              name="state"
              control={control}
              // rules={{ required: 'Field is required' }}
            />
          </FormControl>
        </div>
        <div className="column w-6/12 mt-4">
          <FormControl fullWidth className="label">
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error },
              }) => (
                <>
                  <TextField
                    size="small"
                    value={value}
                    label="City"
                    name={name}
                    onBlur={onBlur}
                    inputRef={ref}
                    onChange={onChange}
                    error={!!error}
                  />

                  <FormHelperText className="text-red-800">{error?.message}</FormHelperText>
                </>
              )}
              name="city"
              control={control}
              // rules={{ required: 'Field is required' }}
            />
          </FormControl>
        </div>
        <div className="column w-6/12 mt-4">
          <FormControl fullWidth className="label">
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error },
              }) => (
                <>
                  <TextField
                    size="small"
                    value={value}
                    type="number"
                    label="Postal Code"
                    name={name}
                    onBlur={onBlur}
                    inputRef={ref}
                    onChange={onChange}
                    error={!!error}
                  />

                  <FormHelperText className="text-red-800">{error?.message}</FormHelperText>
                </>
              )}
              name="zip"
              control={control}
              // rules={{ required: 'Field is required' }}
            />
          </FormControl>
        </div>
        <div className="column w-6/12 mt-4">
          <FormControl fullWidth className="label">
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error },
              }) => (
                <>
                  <TextField
                    size="small"
                    value={value}
                    label="Contact"
                    type="text"
                    name={name}
                    onBlur={onBlur}
                    inputRef={ref}
                    onChange={onChange}
                    error={!!error}
                  />

                  <FormHelperText className="text-red-800">{error?.message}</FormHelperText>
                </>
              )}
              name="contact"
              control={control}
              // rules={{ required: 'Field is required' }}
            />
          </FormControl>
        </div>
        <div className="column w-6/12 mt-4">
          <FormControl fullWidth className="label">
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error },
              }) => (
                <>
                  <TextField
                    size="small"
                    value={value}
                    label="Email"
                    name={name}
                    onBlur={onBlur}
                    inputRef={ref}
                    onChange={onChange}
                    error={!!error}
                  />

                  <FormHelperText className="text-red-800">{error?.message}</FormHelperText>
                </>
              )}
              name="email"
              control={control}
              rules={{
                required: 'Field is required',
                pattern: {
                  // eslint-disable-next-line  no-useless-escape
                  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                  message: 'Not a valid Email',
                },
              }}
            />
          </FormControl>
        </div>
        <div className="column w-6/12 mt-4">
          <FormControl fullWidth className="label">
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error },
              }) => (
                <>
                  <TextField
                    size="small"
                    value={value}
                    label="Registration Number"
                    name={name}
                    onBlur={onBlur}
                    inputRef={ref}
                    onChange={onChange}
                    error={!!error}
                  />
                  <FormHelperText className="text-red-800">{error?.message}</FormHelperText>
                </>
              )}
              name="registrationNo"
              control={control}
            />
          </FormControl>
        </div>

        <div className="column w-full mt-4">
          <FormControl fullWidth className="label">
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error },
              }) => (
                <>
                  <TextField
                    size="small"
                    value={value}
                    label="Address"
                    name={name}
                    onBlur={onBlur}
                    inputRef={ref}
                    onChange={onChange}
                    error={!!error}
                  />

                  <FormHelperText className="text-red-800">{error?.message}</FormHelperText>
                </>
              )}
              name="address"
              control={control}
              // rules={{ required: 'Field is required' }}
            />
          </FormControl>
        </div>

        <div className="column w-full flex justify-center mt-4">
          <Button
            disableElevation
            size="large"
            className="mx-2"
            variant="contained"
            color="error"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            disableElevation
            size="large"
            className="mx-2"
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}
