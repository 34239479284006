import { useEffect } from 'react';
import { XMLParser } from 'fast-xml-parser';
import { Modal,  AccordionDetails, Accordion } from '@mui/material';
import { ResultResponse } from '../service/Tests';

interface Props {
  result?: ResultResponse;
  isOpen: boolean;
  setIsOpen: (active: boolean) => void;
}

export default function TestDetail(props: Props) {
  const { isOpen, result, setIsOpen } = props;
  useEffect(() => {}, [result]);
  if (!result) return <></>;
  return (
    <>
      <Modal
        className="outline-0 flex items-center justify-center"
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <section className="modal-container">
          <div className="section-title text-3xl">Result Details</div>
          <Accordion className="rounded-none shadow border-t">
            <AccordionDetails>
              <div className="mb-4">
                <div className="box-container">
                  <table className="w-full">
                    <thead>
                      <tr>
                        <td colSpan={2} className="p-2 bg-gray-900 text-center text-white">
                          {result.Operation}
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="p-2 bg-gray-300 w-2/12">Message</td>
                        <td className="p-2 w-10/12">{result.Message}</td>
                      </tr>
                      <tr>
                        <td className="p-2 bg-gray-300 w-2/12">Status</td>
                        <td className="p-2 w-10/12">{result.Status}</td>
                      </tr>
                      {Array.isArray(result?.Data) &&
                        result?.Data?.map(({ payload_data }) => {
                          let payload = '';
                          if (payload_data) {
                            const parser = new XMLParser();
                            payload = parser.parse(payload_data);
                          }
                          return Object.entries(payload).map(([typename, type], index) => {
                            let date: any = new Date();

                            if (typename === '?xml') return [<></>];

                            const rows: JSX.Element[] = [
                              <tr key={`${Math.floor(date / 1000)}-${index}`}>
                                <td className="p-2 bg-gray-300 w-2/12">Response</td>
                                <td className="p-2 w-10/12">{payload_data}</td>
                              </tr>,
                            ];
                            rows.push(
                              ...Object.entries(type).map(([name, value], index) => (
                                <tr key={`name_${index}`}>
                                  <td className="p-2 bg-gray-300 w-2/12">{name}</td>
                                  <td className="p-2 w-10/12">
                                    {JSON.stringify(value, null, 2).replace(/'|"|{|}/g, '')}
                                  </td>
                                </tr>
                              ))
                            );

                            return rows;
                          });
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </section>
      </Modal>
    </>
  );
}
