import { Stack, Avatar } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { DEFAULT_PAGE_SIZE } from '../constant';
import { Column } from '../types';
import { DashboardMenu } from '../service/DashboardMenu';
import { FormatDate } from '../utils/format';
import { DEFAULT_IMAGE_URL } from '../constant';

export interface Fields {
  Timestamp: string;
  Status: string;
  Operation: string;
  Company: string;
  User: string;
  id: string;
  UserProfile: string;
}

interface Props {
  extraHeaderContent?: JSX.Element;
  suites?: DashboardMenu;
  page?: number;
}

export default function LatestTests(props: Props) {
  const { page, suites, extraHeaderContent } = props;
  const [data, setData] = useState<Fields[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (suites) {
      let array: Fields[] = [];
      suites.latestTests.forEach((suite) => {
        let status: string = suite.totalTest / 2 <= suite.totalPassed ? 'Passed' : 'Failed';
        array.push({
          id: suite.id,
          Timestamp: FormatDate(suite.testPerformedDate),
          Status: status,
          Operation: suite.suitName,
          Company: suite.companyName,
          User: suite.userLastName,
          UserProfile: suite.userProfileImage,
        });
      });
      setData(array);

      setLoading(false);
    }
  }, [suites]);

  return (
    <section>
      <div className="section-title">
        <div>Latest Tests Results</div>
        {extraHeaderContent}
      </div>
      <div className="box-container">
        <DataGrid
          disableColumnMenu
          rows={data}
          loading={loading}
          columns={columns}
          pageSize={page ? page : DEFAULT_PAGE_SIZE}
          autoHeight={true}
          disableSelectionOnClick={true}
        />
      </div>
    </section>
  );
}

const columns: Column<Fields>[] = [
  { field: 'Timestamp', headerName: 'Date & Time', flex: 1 },
  {
    field: 'Operation',
    headerName: 'Test Profile',
    flex: 1,
    headerClassName: 'pl-custom-0',
    cellClassName: 'flex items-center',
  },
  {
    field: 'Company',
    headerName: 'Company Name',
    flex: 1,
    headerClassName: 'pl-custom-0',
    cellClassName: 'flex items-center',
  },
  {
    field: 'Status',
    headerName: 'Result',
    flex: 1,
    headerClassName: 'pl-custom-0',
    cellClassName: 'flex items-center',
  },
  {
    field: 'User',
    headerName: 'Users',
    headerClassName: 'pl-custom-0',                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        
    flex: 1,
    renderCell: (data) => {
      const { User, UserProfile } = data.row as Fields;
      return (
        <div className="flex items-center justify-center">
          <div>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Avatar
                alt={User}
                src={DEFAULT_IMAGE_URL + UserProfile}
                sx={{ mr: 1.5, height: 35, width: 35 }}
              />
              {User}
            </Stack>
          </div>
        </div>
      );
    },
  },
];
