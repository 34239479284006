import { useState } from 'react';
import { Tab, Button, FormControlLabel, Checkbox } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import AddUser from '../section/users/AddUser';
import AddCompany from '../section/company/AddCompany';
import UsersList from '../section/users/UsersList';
import CompanyList from '../section/company/CompanyList';
import { havePermission, UserData } from '../service/User';
import { Company } from '../service/Company';
import { USERS_URL } from '../routes';

export default function Users() {
  const [editting, setEditting] = useState<Company | UserData | {} | null>(null);
  const [showRevoked, setShowrevoked] = useState(false);

  const loc = useLocation();
  const his = useHistory();

  const tab = loc.pathname.split('/')[3];
  if (!tab) return <Redirect from={loc.pathname} to={`${USERS_URL}/user`} />;

  return (
    <div>
      <div className="h-full">
        <section>
          <div className="section-title">Add {tab.charAt(0).toUpperCase() + tab.slice(1)}</div>
          <div>
            <TabContext value={tab}>
              <div className="box-container flex p-0">
                <TabList
                  onChange={(_, value) => his.push(`${USERS_URL}/${value}`)}
                  aria-label="lab API tabs example"
                  className="box-container py-0 pr-0"
                >
                  {havePermission(['psia-user', 'psia-admin']) && (
                    <Tab
                      onClick={() => setEditting(null)}
                      iconPosition="start"
                      label="Companies"
                      value="company"
                    />
                  )}
                  <Tab
                    onClick={() => setEditting(null)}
                    iconPosition="start"
                    label="Users"
                    value="user"
                  />
                </TabList>
                <div className="flex items-center justify-end column">
                  <FormControlLabel
                    className="mr-4"
                    onChange={(event, value) => setShowrevoked(value)}
                    checked={showRevoked}
                    control={<Checkbox onChange={(event, value) => setShowrevoked(value)} />}
                    label="Show Revoked"
                  />
                  {((tab === 'company' && havePermission(['psia-admin', 'psia-user'])) ||
                    (tab === 'user' &&
                      havePermission(['psia-admin', 'psia-user', 'company-admin']))) && (
                    <Button disableElevation variant="contained" onClick={() => setEditting({})}>
                      Add {tab.charAt(0).toUpperCase() + tab.slice(1)}
                    </Button>
                  )}
                </div>
              </div>
              <div className="mt-4">
                <TabPanel className="p-0" value="company">
                  {!editting ? (
                    <CompanyList
                      className="p-0"
                      showRevoked={showRevoked}
                      disablHeader
                      onEdit={setEditting}
                    />
                  ) : (
                    <AddCompany
                      onCancel={() => setEditting(null)}
                      onSubmit={() => setEditting(null)}
                      initialValue={(editting as Company | undefined) ?? {}}
                    />
                  )}
                </TabPanel>
                <TabPanel className="p-0" value="user">
                  {!editting ? (
                    <UsersList
                      className="p-0"
                      disablHeader
                      showRevoked={showRevoked}
                      onEdit={setEditting}
                    />
                  ) : (
                    <AddUser
                      onCancel={() => setEditting(null)}
                      onSubmit={() => setEditting(null)}
                      initialValue={(editting as UserData | undefined) ?? {}}
                    />
                  )}
                </TabPanel>
              </div>
            </TabContext>
          </div>
        </section>
      </div>
    </div>
  );
}
