// import { Button } from '@mui/material';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { TYPE_URL } from '../routes';
import AddSuiteModel from '../section/AddSuite';
import { getSuits, Suite, deleteSuit } from '../service/Tests';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { havePermission } from '../service/User';
import { useConfirm } from 'material-ui-confirm';

export default function SuitsList() {
  const confirm = useConfirm();
  const [suits, setSuits] = useState<Suite[]>([]);
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const [activeSuit, setActive] = useState<Suite>();

  useEffect(() => {
    if (loading && suits.length === 0) {
      const loadingtoast = toast.loading('Loading Suits');
      getSuits()
        .then((tree) => setSuits(tree))
        .catch(({ response }: AxiosError) =>
          toast.error(response?.data.message ?? 'Some Error Occured')
        )
        .finally(() => {
          toast.dismiss(loadingtoast);
          setLoading(false);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [suits, loading]);

  return (
    <div className="flex flex-col">
      <section>
        <div className="section-title">Suites</div>
        {havePermission(['psia-admin']) && (
          <div className="box-container flex justify-between">
            <AddSuiteModel
              edit={edit}
              initialState={activeSuit}
              setActive={setActive}
              setEdit={setEdit}
              AfterSubmit={() => {
                setSuits([]);
                setLoading(true);
              }}
            />
          </div>
        )}
        <div className="flex-grid mt-6">
          {suits.map((suit) => (
            <div key={suit._id} className="column w-4/12 ">
              <div className="my-1 flex-grid box-container items-center">
                <div className="column">
                  <Link to={TYPE_URL.replace(':suitid', suit._id)} className="w-full  block p-6">
                    {suit.name}
                  </Link>
                </div>
                {havePermission(['psia-admin']) && (
                  <div className="column w-auto">
                    <Stack
                      className="edit-del-button"
                      direction="row"
                      alignItems="center"
                      spacing={1}
                    >
                      <Button
                        style={{ color: '#76B6B0' }}
                        onClick={(event) => {
                          setEdit(true);
                          setActive(suit);
                        }}
                      >
                        <EditIcon fontSize="small" sx={{ mr: 0.2 }} />
                      </Button>
                      <Button
                        // alignItems="center"
                        style={{ color: '#FF4D5E' }}
                        onClick={() => {
                          confirm({ description: `Are you sure you want to do this?` })
                            .then(() => {
                              toast.promise(deleteSuit(suit._id), {
                                loading: 'Loading',
                                success: () => {
                                  setSuits([]);
                                  setLoading(true);
                                  return `Successfully Deleted`;
                                },
                                error: ({ response }: AxiosError) =>
                                  response?.data.message ?? 'Some Error Occured',
                              });
                            })
                            .catch(() => console.log('Deletion cancelled.'));
                        }}
                      >
                        <DeleteIcon fontSize="small" sx={{ mr: 0.2 }} />
                      </Button>
                    </Stack>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
