import { useEffect, useState } from 'react';
import { XMLParser } from 'fast-xml-parser';

import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import {
  runTests,
  useTestsStore,
  getTestsTree,
  SuiteTree,
  TestPayload,
  clearRecentTests,
  Suite,
} from '../service/Tests';
import { useFieldArray, useForm } from 'react-hook-form';
import TestField from '../components/TestField';
import TestsContainer from '../section/TestsContainer';
import TestRunner from '../section/TestRunner';
import { Company } from '../service/Company';
// import { fil } from 'date-fns/locale';
import DataScript from '../section/DataScript';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

const NewTest = () => {
  const recenttest = useTestsStore((state) => state.recenttest);
  const testProcessId = useTestsStore((state) => state.testProcessId);
  const [suitetree, setSuiteTree] = useState<SuiteTree[]>([]);
  const [selectedSuite, setSelectedsuite] = useState<Suite | undefined>(recenttest?.suite);
  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>(recenttest?.company);

  const dataScripts = useTestsStore((state) => state.scriptTest);
  // const store = useTestsStore((state) => state);
  const isDataScript = useTestsStore((state) => state.dataScript);
  const scriptResult = useTestsStore((state) => state.scriptResult);
  const [open, setOpen] = useState<boolean>(false);
  // const [isDataScript, setIsDataScript] = useState<boolean>(true);
  const running = useTestsStore((state) => state.running);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, submitCount },
  } = useForm({});

  const { fields: orignalfields, remove } = useFieldArray({ control, name: 'tests' });
  const fields = orignalfields as TestPayload[];
  if (!fields.length && testProcessId && !isDataScript) {
    setValue('tests', recenttest?.tests);
  }

  useEffect(() => {
    let loading: string = '';
    if (!suitetree.length && !loading) {
      loading = toast.loading('Loading Tests');
      getTestsTree()
        .then((tree) => setSuiteTree(tree))
        .catch(({ response }: AxiosError) =>
          toast.error(response?.data.message ?? 'Some Error Occured')
        )
        .finally(() => toast.dismiss(loading));

      setOpen(false);
    }
  }, [suitetree]);

  useEffect(() => {
    if (selectedSuite && suitetree.length && !dataScripts?.length) {
      setValue('tests', []);
      const suite = suitetree.find((suite) => suite._id === selectedSuite?._id);
      const tests = suite?.types.flatMap((type) => type.tests.filter((test) => test.isDataScript));
      if (tests?.length && selectedSuite) {
        if (!isDataScript) return useTestsStore.setState({ scriptTest: tests });
        useTestsStore.setState({ dataScript: true });
        setOpen(true);
        useTestsStore.setState({ scriptTest: tests });
        runTests(tests, selectedSuite, selectedCompany, true)
          .then((resp) => {
            setOpen(false);
          })
          .catch(({ response }: AxiosError) => {
            setOpen(false);
            toast.error(response?.data.message ?? 'Test Failed');
          });
      }
    }
  }, [selectedSuite, suitetree, dataScripts]);

  useEffect(() => {
    if (Object.keys(errors).length) {
      toast.error('Please Enter value in Tests Fields');
      console.log('errors :: ', errors);
    }
  }, [errors, submitCount]);
  const submit = async () => {
    if (selectedSuite) {
      useTestsStore.setState({ dataScript: false });
      await runTests(fields, selectedSuite, selectedCompany, false)
        .then((resp) => console.log('---resp'))
        .catch(({ response }: AxiosError) => toast.error(response?.data.message ?? 'Test Failed'));
    }
  };

  const submitDataScript = async () => {
    setOpen(true);
    if (selectedSuite && dataScripts) {
      runTests(dataScripts, selectedSuite, selectedCompany, true)
        .then((resp) => setOpen(false))
        .catch(({ response }: AxiosError) => toast.error(response?.data.message ?? 'Test Failed'));
    }
  };

  return (
    <section className="flex-grid px-0">
      <div>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <div
        className={`column pr-0 sticky top-4 duration-1000 transition-[width] ${
          // !testProcessId && isDataScript ? 'w-4/12' : 'w-0 pl-0'
          isDataScript ? 'w-4/12' : 'w-0 pl-0'
        }`}
      >
        <TestsContainer
          className={`rounded-r-none h-main overflow-y-scroll ${
            // !testProcessId && isDataScript ? '' : 'p-0'
            isDataScript ? '' : 'p-0'
          }`}
          suiteTree={suitetree}
          onChange={({ tests, suite, company }) => {
            setValue('tests', []);
            setSelectedsuite(suite);
            setSelectedCompany(company);

            const toadd = tests.filter((test) => !fields.some((field) => field._id === test._id));

            const toremove = fields.filter(
              (field) => !tests.some((test) => field._id === test._id)
            );

            if (toadd.length) {
              setValue(
                'tests',
                [...toadd, ...fields].sort((a, b) => a.testRunOrder - b.testRunOrder)
              );
            }

            if (toremove.length) {
              toremove.forEach((test) => {
                const index = fields.findIndex((field) => field._id === test._id);
                remove(index);
              });
            }
          }}
        />
      </div>
      <div
        className={`column duration-1000 transition-[width,padding] ${
          // !testProcessId && isDataScript ? 'pl-3 w-8/12 ' : 'w-full'
          isDataScript ? 'pl-3 w-8/12 ' : 'w-full'
        }`}
      >
        <div className="column mb-4">
          <TestRunner
            expand={!!testProcessId && !isDataScript}
            onBack={() => {
              clearRecentTests();
              // setTestProcessId('');
              useTestsStore.setState({ dataScript: true });
              // setIsDataScript(true);
            }}
            field={fields}
            onStart={handleSubmit(submit)}
          />
        </div>
        <div className="flex-grid">
          <section
            className={`column duration-1000 transition-[width] overflow-hidden ${
              // testProcessId && !isDataScript ? 'w-6/12' : 'w-full'
              !isDataScript ? 'w-6/12' : 'w-full'
            }`}
          >
            <div className="flex">
              <div className="back-button flex justify-start column w-6/12 section-title mb-2 ">
                Sample Test Data
              </div>
              <div className="add-new flex justify-end column w-6/12 section-title">
                <Button
                  variant="contained"
                  disableElevation
                  disabled={!dataScripts?.length || !scriptResult || running}
                  onClick={submitDataScript}
                >
                  Regenerate Test Data
                </Button>
              </div>
            </div>
            {/* <div className="section-title">Sample Test Data</div> */}
            {Object.entries(scriptResult ?? {})?.map(([testid, result], index) => {
              if (result) {
                return (
                  <DataScript
                    isDataScript={isDataScript}
                    key={testid + '--' + index}
                    result={result}
                  />
                );
              }
            })}

            <div className="section-title mt-4">Tests Script</div>
            <div className="box-container h-[60vh] overflow-y-scroll">
              {fields.map((field, testindex) => {
                if (field.isDataScript) return false;
                const test = field as TestPayload;
                const error = (errors?.tests && errors?.tests[testindex]) ?? {};
                return (
                  <TestField
                    key={field.id ?? testindex}
                    errors={error}
                    testindex={testindex}
                    test={test}
                    control={control}
                    isDataScript={isDataScript}
                  />
                );
              })}
            </div>
          </section>

          <section
            className={`column duration-1000 transition-[width,padding] overflow-hidden ${
              // testProcessId && !isDataScript ? 'w-6/12' : 'w-0 px-0'
              !isDataScript ? 'w-6/12' : 'w-0 px-0'
            }`}
          >
            <div className="section-title whitespace-nowrap">Tests Console</div>
            <div className="box-container h-[68vh] overflow-y-scroll">
              {Object.entries(recenttest?.result ?? {})?.map(([testid, result], index) => {
                let { Operation, Message, Status, Data } = result.responseData[0];
                return (
                  <table className="w-full" key={testid + '--' + index}>
                    <thead>
                      <tr>
                        <td colSpan={2} className="p-2 bg-gray-900 text-center text-white">
                          {Operation}
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="p-2 bg-gray-300 w-2/12">Message</td>
                        <td className="p-2 w-10/12">{Message}</td>
                      </tr>
                      <tr>
                        <td className="p-2 bg-gray-300 w-2/12">Status</td>
                        <td className="p-2 w-10/12">{Status}</td>
                      </tr>
                      {Array.isArray(Data) &&
                        Data?.map(({ payload_data }) => {
                          let payload = '';
                          if (payload_data) {
                            const parser = new XMLParser();
                            payload = parser.parse(payload_data);
                          }
                          return Object.entries(payload).map(([typename, type], index) => {
                            let date: any = new Date();

                            if (typename === '?xml') return [<></>];

                            const rows: JSX.Element[] = [
                              <tr key={`${Math.floor(date / 1000)}-${index}`}>
                                <td className="p-2 bg-gray-300 w-2/12">Response</td>
                                <td className="p-2 w-10/12">{payload_data}</td>
                              </tr>,
                            ];
                            rows.push(
                              ...Object.entries(type).map(([name, value], index) => (
                                <tr key={`name_${index}`}>
                                  <td className="p-2 bg-gray-300 w-2/12">{name}</td>
                                  <td className="p-2 w-10/12">
                                    {JSON.stringify(value, null, 2).replace(/'|"|{|}/g, '')}
                                  </td>
                                </tr>
                              ))
                            );

                            return rows;
                          });
                        })}
                    </tbody>
                  </table>
                );
              })}
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};
export default NewTest;
