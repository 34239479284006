import * as React from 'react';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import Avatar from '@mui/material/Avatar';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  RunTestTopBar,
  CustomSelectBox,
  RunTestSearchBar,
  SearchCheckBox,
} from '../components/styled/RunTestTopBar.styled';
import { DataGrid } from '@mui/x-data-grid';

import Stack from '@mui/material/Stack';
import { DEFAULT_PAGE_SIZE } from '../constant';

const columns = [
  // { field: 'id', headerName: 'ID', flex: 1},
  {
    field: 'users',
    headerName: 'Users',
    flex: 1,

    renderCell: () => {
      return (
        <div>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar
              alt="Remy Sharp"
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTtWG2jPOISq_1G3-8w4AgsfWbqkzXvzx_wkg&usqp=CAU"
              sx={{ mr: 1.5, height: 35, width: 35 }}
            />
            Name
          </Stack>
        </div>
      );
    },
  },
  { field: 'testName', headerName: 'Test Name', flex: 2 },
  {
    field: 'results',
    headerName: 'Results',
    flex: 1,
    renderCell: () => {
      return <div className="text-red-600">Failed</div>;
    },
  },
  { field: 'date', headerName: 'Date', flex: 1 },
  {
    field: 'actions',
    headerName: 'Actions',
    flex: 1,
    renderCell: () => {
      return (
        <div>
          <Stack className="edit-del-button" direction="row" alignItems="center" spacing={1}>
            <Button
              style={{ color: '#76B6B0' }}
              onClick={(event) => {
                // handleEdit(event, cellValues);
              }}
            >
              <EditIcon fontSize="small" sx={{ mr: 0.2 }} /> Edit
            </Button>
            <Button
              // alignItems="center"
              style={{ color: '#FF4D5E' }}
              onClick={(event) => {
                // handleDelete(event, cellValues);
              }}
            >
              <DeleteIcon fontSize="small" sx={{ mr: 0.2 }} /> Delete
            </Button>
          </Stack>
        </div>
      );
    },
  },
];

const rows = [
  {
    id: 1,
    users: 'Snow',
    testName: 'Jon Doe is a dummy user',
    results: 35,
    date: '20-oct-2021',
  },
];

export default function History() {
  return (
    <div style={{ width: '100%' }}>
      <div>
        <RunTestTopBar>
          <div className="text-primary font-semibold">History</div>
        </RunTestTopBar>
      </div>
      <RunTestSearchBar>
        <CustomSelectBox>
          <option>one</option>
          <option>one</option>
          <option>one</option>
          <option>one</option>
        </CustomSelectBox>
        <CustomSelectBox>
          <option>one</option>
          <option>one</option>
          <option>one</option>
          <option>one</option>
        </CustomSelectBox>
        <SearchCheckBox>
          {/* <Checkbox onChange={handleCheckBox} /> */}
          Certifying
        </SearchCheckBox>
      </RunTestSearchBar>
      <DataGrid
        disableColumnMenu
        rows={rows}
        columns={columns}
        pageSize={DEFAULT_PAGE_SIZE}
        checkboxSelection
        autoHeight={true}
        disableSelectionOnClick={true}
      />
    </div>
  );
}
