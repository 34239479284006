import axios, { AxiosError } from 'axios';
import { useUserStore } from '../service/User';
import { createBrowserHistory } from 'history';
import { SIGNIN_URL } from '../routes';

export const history = createBrowserHistory();
export const baseURL = 'https://api.psialliance.net/api/v1';

let api = axios.create({
  baseURL: baseURL,
  headers: {
    'content-Type': 'application/json',
    Accept: 'application/json',
  },
});

api.interceptors.request.use((config) => {
  const { token } = useUserStore.getState();
  if (config?.headers && token) config.headers['x-Access-token'] = token;
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    const { response } = error;

    if ((response?.status === 500 || response?.status === 403) && response?.data.auth === false) {
      useUserStore.setState({ expired: true });
      history.push(SIGNIN_URL);
    }

    return Promise.reject(error);
  }
);

export default api;

export interface Response<T = unknown> {
  message: string;
  data: T;
}
