import { useEffect, useState } from 'react';
import {
  Modal,
  Button,
  FormControl,
  TextField,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  OutlinedInput,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Test, addTest, updateTest, getTestWithSuitId } from '../service/Tests';
import { AxiosError } from 'axios';
import { Link } from 'react-router-dom';
import { TYPE_URL } from '../routes';
import { havePermission } from '../service/User';

interface FormState extends Test {}

interface Props {
  AfterSubmit?: (server: any) => void;
  suitid: string;
  typeid: string;
  edit: boolean;
  initialState?: Test;
  setEdit: (active: boolean) => void;
  setActive: (active: Test) => void;
}

export default function AddTestModel(props: Props) {
  const {
    edit,
    setEdit,
    AfterSubmit,
    initialState: initialState,
    setActive,
    suitid,
    typeid,
  } = props;
  const [open, setOpen] = useState(false);
  const [tests, setTests] = useState<Test[]>([]);
  const [priorityOrder, setPriorityOrder] = useState<number[]>([]);
  const [readOnly] = useState(havePermission(['psia-admin']) ? false : true);

  // const [suits, setSuits] = useState<Suite[]>([]);
  // const [loading, setLoading] = useState(true);

  const [filedList, setFiledList] = useState([
    { required: false, key: 'need_records', type: 'number', dependOnTestId: '', value: '' },
  ]);
  const [title, setTitle] = useState('Add New Test');

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
  };

  useEffect(() => {
    getTestWithSuitId(suitid)
      .then((data) => {
        setTests(data.tests);
        setPriorityOrder(data.occupiedRunOrders);
      })
      .catch(({ response }: AxiosError) =>
        toast.error(response?.data.message ?? 'Some Error Occurred')
      )
      .finally(() => {
        toast.dismiss('');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (edit) setOpen(true);
    if (!initialState) {
      const max = Math.max(...priorityOrder);
      setValue('testRunOrder', max + 1);
    }
  }, [edit, priorityOrder]);

  useEffect(() => {
    reset(initialState);
    setTitle(readOnly ? 'View Test' : 'Update Test');
    if (initialState?.fields) setFiledList(initialState?.fields);
  }, [initialState]);

  const { handleSubmit, setValue, reset, control, setError, register } = useForm<FormState>({
    defaultValues: {
      title: '',
      name: '',
      description: '',
      script: '',
      typeId: typeid,
      suitId: suitid,
      fields: [],
      criteria: 'Success',
      certification_required: false,
      testRunOrder: 1,
      requestType: initialState?.requestType
        ? initialState.requestType
          ? initialState.requestType
          : ['GET']
        : ['GET'],
      isDataScript: false,
      status: 1,
    },
  });

  async function OnSubmit(form: FormState) {
    const test = {} as Test;
    form.description = 'Description of Test will be add once filed is added in the form!';
    form.name = form.script.slice(form.script.indexOf(' ') + 1, form.script.indexOf('('));
    if (initialState?._id) {
      form._id = initialState._id;
      updateTest(form)
        .then((tests) => {
          AfterSubmit?.(form);
          setOpen(false);
          setEdit(false);
          setActive(test);
        })
        .catch(({ response }: AxiosError) => {
          response?.data['errors'].forEach((element: any) => {
            setError('testRunOrder', {
              type: 'api',
              message: element?.testRunOrder,
            });
          });

          const errors: [] = response?.data['errors'] ? response?.data['errors'].toast : []; // setError();
          toast.error(response?.data.message ?? 'Some Error Occurred');
        })
        .finally(() => {
          toast.dismiss('');
        });
    } else {
      addTest(form)
        .then((tests) => {
          AfterSubmit?.(form);
          setOpen(false);
          setEdit(false);
          setActive(test);
        })
        .catch(({ response }: AxiosError) => {
          response?.data['errors'].forEach((element: any) => {
            setError('testRunOrder', {
              type: 'api',
              message: element?.testRunOrder,
            });
          });

          const errors: [] = response?.data['errors'] ? response?.data['errors'].toast : []; // setError();
          toast.error(response?.data.message ?? 'Some Error Occurred');
        })
        .finally(() => {
          toast.dismiss('');
        });
    }
  }

  return (
    <>
      <div className="back-button flex justify-start column w-6/12 ">
        <Link to={TYPE_URL.replace(':suitid', suitid)}>
          <Button variant="contained" disableElevation>
            Back
          </Button>
        </Link>
      </div>

      <div className="add-new flex justify-end column w-6/12">
        {havePermission(['psia-admin']) && (
          <Button
            variant="contained"
            disableElevation
            onClick={() => {
              setOpen(true);
              setTitle('Add New Test');
            }}
          >
            Add New
          </Button>
        )}
      </div>

      <Modal
        className="outline-0 flex items-center justify-center"
        open={open}
        onClose={() => {
          setOpen(false);
          setEdit(false);
          setTitle('Add New Test');
        }}
      >
        <section className="modal-container">
          <div className="section-title text-3xl"> {title} </div>
          <form className="mt-2" onSubmit={handleSubmit(OnSubmit)}>
            <FormControl fullWidth className="label label-small">
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <TextField
                      value={value || ''}
                      label="Test Title"
                      name={name}
                      onBlur={onBlur}
                      inputRef={ref}
                      inputProps={{ maxLength: 60 }}
                      onChange={onChange}
                      disabled={readOnly}
                      error={!!error}
                    />

                    <FormHelperText className="text-red-800">{error?.message}</FormHelperText>
                  </>
                )}
                name="title"
                control={control}
                rules={{ required: 'Field is required' }}
              />
            </FormControl>

            <div className="flex-grid mt-2">
              <div className="column w-4/12">
                <FormControl fullWidth className="label label-small">
                  <Controller
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { error },
                    }) => (
                      <>
                        <TextField
                          type={'number'}
                          value={initialState ? value : value}
                          label="Priority Order"
                          name={name}
                          onBlur={onBlur}
                          inputRef={ref}
                          onChange={onChange}
                          variant="outlined"
                          disabled={readOnly}
                          inputProps={{ maxLength: 60 }}
                          error={!!error}
                        />

                        <FormHelperText className="text-red-800">{error?.message}</FormHelperText>
                      </>
                    )}
                    name="testRunOrder"
                    control={control}
                    rules={{ required: 'Field is required' }}
                  />
                </FormControl>
              </div>

              <div className="column w-3/12">
                <FormControl fullWidth className="mb-2">
                  <InputLabel>Mandatory For Certification</InputLabel>
                  <Select
                    label="Mandatory For Certification"
                    {...register('certification_required')}
                    defaultValue={initialState?.certification_required}
                    disabled={readOnly}
                  >
                    <MenuItem value="true">True</MenuItem>
                    <MenuItem value="false">False</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="column w-3/12">
                <FormControl fullWidth className="mb-2 w-12/12">
                  <InputLabel>Status</InputLabel>
                  <Select
                    label="Status"
                    {...register('status')}
                    defaultValue={initialState?.status}
                    disabled={readOnly}
                  >
                    <MenuItem value="1">Draft</MenuItem>
                    <MenuItem value="2">Preliminary</MenuItem>
                    <MenuItem value="3">Released</MenuItem>
                    <MenuItem value="4">Deprecated</MenuItem>
                  </Select>
                </FormControl>
              </div>

              {/* <div className="column w-4/12">
                <FormControl fullWidth className="mb-2">
                  <InputLabel>isDataScript</InputLabel>
                  <Checkbox {...register('isDataScript')} />
                </FormControl>
              </div> */}
              <div className="pt-2 pb-6 border-b column w-2/12">
                <FormControlLabel
                  value="end"
                  // disabled={}
                  control={
                    <Checkbox
                      value={initialState?.isDataScript || false}
                      color="primary"
                      checked={initialState?.isDataScript}
                      disabled={readOnly}
                    />
                  }
                  label={'DataScript'}
                  className="text-primary"
                  labelPlacement="end"
                />
              </div>

              <div className="column w-6/12">
                <FormControl fullWidth className="mb-2">
                  <InputLabel>Success Criteria</InputLabel>
                  <Select
                    {...register('criteria')}
                    label="Success Criteria"
                    disabled={readOnly}
                    defaultValue={initialState?.criteria}
                  >
                    <MenuItem value="success">Success</MenuItem>
                    <MenuItem value="fail">Fail</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="column w-6/12">
                <FormControl fullWidth className="mb-2">
                  <InputLabel>Request Type</InputLabel>
                  <Select
                    label="Request Type"
                    {...register('requestType')}
                    defaultValue={
                      initialState?.requestType
                        ? initialState.requestType
                          ? initialState.requestType
                          : ['GET']
                        : ['GET']
                    }
                    multiple
                    onChange={handleChange}
                    disabled={readOnly}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => selected.join(', ')}
                  >
                    <MenuItem value="GET">Get</MenuItem>
                    <MenuItem value="POST">Post</MenuItem>
                    <MenuItem value="PUT">Put</MenuItem>
                    <MenuItem value="DELETE">Delete</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            {filedList.map((field, index) => {
              return (
                <div className="fields flex-grid mt-2" key={index.toString()}>
                  <div className="column w-4/12">
                    <FormControl fullWidth className="mb-2">
                      <InputLabel>Filed Key</InputLabel>
                      <Select
                        label="filed type"
                        defaultValue={field?.key}
                        disabled={readOnly}
                        {...register(`fields.${index}.key`)}
                      >
                        <MenuItem value="need_records">need_records</MenuItem>
                        <MenuItem value="chi_id">chi_id</MenuItem>
                        <MenuItem value="id">Id</MenuItem>
                        <MenuItem value="new_role">New Role</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="column w-4/12">
                    <FormControl fullWidth className="mb-2">
                      <InputLabel>Filed Type</InputLabel>
                      <Select
                        label="filed type"
                        disabled={readOnly}
                        defaultValue={field?.type}
                        {...register(`fields.${index}.type`)}
                      >
                        <MenuItem value="number">Number</MenuItem>
                        <MenuItem value="string">String</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div className="column w-4/12">
                    <FormControl fullWidth className="mb-2">
                      <InputLabel>Is Required</InputLabel>
                      <Select
                        label="required"
                        disabled={readOnly}
                        defaultValue={field?.required}
                        {...register(`fields.${index}.required`)}
                      >
                        <MenuItem value="true">True</MenuItem>
                        <MenuItem value="false">False</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="column w-6/12">
                    <FormControl fullWidth className="mb-2">
                      <InputLabel>Depend On Test</InputLabel>
                      <Select
                        label="required"
                        disabled={readOnly}
                        defaultValue={field?.dependOnTestId}
                        {...register(`fields.${index}.dependOnTestId`)}
                      >
                        {tests.map((test) => (
                          <MenuItem value={test._id} key={test._id}>
                            {test.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="column w-6/12">
                    <Controller
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { error },
                      }) => (
                        <>
                          <TextField
                            value={value ?? ''}
                            label="Default Value"
                            name={name}
                            onBlur={onBlur}
                            inputRef={ref}
                            onChange={onChange}
                            disabled={readOnly}
                            inputProps={{ maxLength: 400 }}
                            error={!!error}
                          />

                          <FormHelperText className="text-red-800">{error?.message}</FormHelperText>
                        </>
                      )}
                      name={`fields.${index}.value`}
                      control={control}
                      rules={{}}
                    />
                  </div>
                </div>
              );
            })}

            {!readOnly ? (
              <div className=" column w-12/12 box-container flex justify-end">
                <Button
                  // alignItems="center"
                  style={{ color: '#76b6b2' }}
                  onClick={() => {
                    setFiledList([
                      ...filedList,
                      { required: false, key: '', type: '', dependOnTestId: '', value: '' },
                    ]);
                  }}
                >
                  <AddIcon fontSize="medium" sx={{ mr: 0.1 }} />
                </Button>
              </div>
            ) : (
              <></>
            )}

            <FormControl fullWidth className="label label-small">
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <TextField
                      multiline
                      value={value ?? ''}
                      label="Test Script"
                      rows={6}
                      name={name}
                      onBlur={onBlur}
                      inputRef={ref}
                      disabled={readOnly}
                      onChange={onChange}
                      inputProps={{ maxLength: 120000000 }}
                      error={!!error}
                    />

                    <FormHelperText className="text-red-800">{error?.message}</FormHelperText>
                  </>
                )}
                name="script"
                control={control}
                rules={{ required: 'Field is required' }}
              />
            </FormControl>

            {!readOnly ? (
              <div className="flex justify-center">
                <Button
                  variant="contained"
                  className="mt-2"
                  disableElevation
                  onClick={handleSubmit(OnSubmit)}
                >
                  Save
                </Button>
              </div>
            ) : (
              <></>
            )}
          </form>
        </section>
      </Modal>
    </>
  );
}
