import { AxiosError } from 'axios';
import api, { Response } from '../utils/api';

export interface DashboardMenu {
  totalCertificates?: string;
  totalRunningTests?: string;
  totalSuccessfullTests?: string;
  totalTestsRuns?: string;
  latestTests: [LatestTest];
}

export interface TestResults {
  limit: number;
  page: number;
  sort: string;
  sortValue: string;
  totalPages: number;
  results: [LatestTest];
}

export interface LatestTest {
  adapterUrl: string;
  companyId: string;
  companyName: string;
  id: string;
  issuerSignature: string;
  password: string;
  provisionIp: string;
  suitName: string;
  suspended: boolean;
  testPerformedDate: string;
  tests: [];
  totalMandatoryToPassed: number;
  totalPassed: number;
  totalTest: number;
  type: string;
  userEmail: string;
  userId: string;
  userProfile: string;
  userLastName: string;
  username: string;
  userProfileImage: string;
  validForCertification: boolean;
  _id: string;
}

export async function getDashBoardMenu(): Promise<DashboardMenu> {
  const { data: response } = await api
    .get<Response<DashboardMenu>>(`/certificate/getDashBoardMenu`)
    .catch((error: AxiosError) => {
      throw error;
    });

  return response.data;
}

export async function getTests(page: number, perPage: number): Promise<TestResults> {
  const { data: response } = await api
    .get<Response<TestResults>>(`/suit-tests/getAllTestsResults?limit=${perPage}&page=${page}`)
    .catch((error: AxiosError) => {
      throw error;
    });
  return response.data;
}

// export async function getAllTests(): Promise<:any> {
//   const { data: response } = await api
//     .get<Response: any(`/suit-tests/getAllTestsResults`)
//     .catch((error: AxiosError) => {
//       throw error;
//     });

//   return response;
// }
