import { AxiosError } from 'axios';
import create, { GetState, SetState, StoreApi } from 'zustand';
import { persist } from 'zustand/middleware';
import api, { Response } from '../utils/api';
import { useAdapterStore } from './Adapter';

interface Status {
  networkStatus: string;
  availableIp: string;
}

export interface Server {
  networkInterfaces: any[];
  _id: string; // '61bc81d5ab0a00623ca0e0f9';
  region: string; // 'ohio';
  containerArn: string; // 'arn:aws:ecs:us-east-2:104650672539:container/PSIA-Tester/bb5b16d82273497eb302f740129c3f1a/fd895dbc-79ff-4ce9-abe2-26724f0dd643';
  taskArn: string; // 'arn:aws:ecs:us-east-2:104650672539:task/PSIA-Tester/bb5b16d82273497eb302f740129c3f1a';
  name: string; // 'PLA-Tester';
  image: string; // '104650672539.dkr.ecr.us-east-2.amazonaws.com/psia-tester';
  lastStatus: 'STOPED' | 'PENDING' | 'RUNNING'; // 'PENDING';
  cpu: string; // '0';
  memory: string; // '512';
  memoryReservation: string; // '256';
}

interface ServerStoreState extends Partial<Server>, Partial<Status> {}

export const useServerStore = create(
  persist<
    ServerStoreState,
    SetState<ServerStoreState>,
    GetState<ServerStoreState>,
    StoreApi<ServerStoreState>
  >(
    () => ({
      // // // ================== Server
      // networkInterfaces: [],
      // _id: '61bf8c00b7a0dc6d438a5e4d',
      // region: 'singapore',
      // containerArn:
      //   'arn:aws:ecs:ap-southeast-1:104650672539:container/PSIA-Tester/c2bf5f9f8e5046d58f2829d28abcff3c/d63f6f83-8815-4612-b01b-1b97eacb01bd',
      // taskArn:
      //   'arn:aws:ecs:ap-southeast-1:104650672539:task/PSIA-Tester/c2bf5f9f8e5046d58f2829d28abcff3c',
      // name: 'PLA-Tester',
      // image: '104650672539.dkr.ecr.ap-southeast-1.amazonaws.com/psia-tester',
      // lastStatus: 'PENDING',
      // cpu: '0',
      // memory: '512',
      // memoryReservation: '256',
      //  // // ================== Status
      // networkStatus: 'active',
      // availableIp: '46.137.239.181',
    }),
    {
      name: 'ServerStore',
      getStorage: () => localStorage,
    }
  )
);

export async function CheckStatus(
  instanceID: string,
  tries = 0,
  delay = 5000,
  maxtries = 10
): Promise<Response<Status>> {
  const { data } = await api
    .get<Response<Status>>(`engine/status/${instanceID}`)
    .catch(async (resp) => {
      if (tries > maxtries - 1) throw resp;
      else {
        await new Promise((resolve) => setTimeout(resolve, delay));
        const status = await CheckStatus(instanceID, tries + 1, delay, maxtries);
        return { data: status };
      }
    });
  useServerStore.setState({ ...data.data });
  return data;
}

export async function Create(region: string) {
  const { data } = await api
    .post<Response<Server>>('engine/create', { region: region })
    .catch((error: AxiosError) => {
      throw error;
    });
  useServerStore.setState({ ...data.data });
  return data;
}

export async function Disconnect(instanceID = useServerStore.getState()?._id) {
  const { data } = await api.get<Response<{}>>(`engine/terminate/${instanceID}`);
  useServerStore.setState({}, true);
  useAdapterStore.setState({}, true);
  return data;
}

export async function TerminateAll() {
  api.get<Response<Server[]>>(`provisions/`).then((Resp) => {
    Resp.data.data
      .filter((server) => server.lastStatus === 'RUNNING' || server.lastStatus === 'PENDING')
      .forEach((server) => api.get<Response<Server[]>>(`engine/terminate/${server._id}`));
  });
}
