import { Delete, Edit } from '@mui/icons-material';
import { useEffect, useMemo, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import toast from 'react-hot-toast';
import { AxiosError } from 'axios';
import { Avatar, Button } from '@mui/material';
import {
  activateUser,
  deleteUser,
  getUsers,
  havePermission,
  RoleSlugName,
  UserData,
} from '../../service/User';
import { Column } from '../../types';
import { DEFAULT_PAGE_SIZE } from '../../constant';
import { useConfirm } from 'material-ui-confirm';

interface Props {
  extraHeaderContent?: JSX.Element;
  onEdit?: (data: UserData) => void;
  disablHeader?: boolean;
  showRevoked?: boolean;
  className?: string;
}

export default function UsersList(props: Props) {
  const { extraHeaderContent, onEdit, disablHeader, className, showRevoked = true } = props;
  const [data, setData] = useState<UserData[]>([]);
  const [loading, setLoading] = useState(true);
  const [filterdata, setFilterdata] = useState<UserData[]>([]);
  const confirm = useConfirm();

  useEffect(() => {
    if (loading && data.length === 0)
      getUsers()
        .then((data) => {
          const indexeddata = data.map((user) => ({ ...user, id: user._id }));
          setData(indexeddata);
        })
        .catch(({ response }: AxiosError) =>
          toast.error(response?.data.message ?? 'Some Error Occured')
        )
        .finally(() => setLoading(false));
  }, [data, loading]);

  useEffect(() => {
    let filterdata = showRevoked ? data : data.filter((item) => !item.suspended);
    setFilterdata(filterdata);
  }, [data, showRevoked]);

  function onDelete(user: UserData) {
    toast.promise(deleteUser(user), {
      loading: 'Loading',
      success: () => {
        setData([]);
        setLoading(true);
        return `Successfully Deactivated`;
      },
      error: ({ response }: AxiosError) => response?.data.message ?? 'Some Error Occured',
    });
  }

  function onActive(user: UserData) {
    toast.promise(activateUser(user), {
      loading: 'Loading',
      success: () => {
        setData([]);
        return `Successfully Activated`;
      },
      error: ({ response }: AxiosError) => response?.data.message ?? 'Some Error Occured',
    });
  }

  const columns: Column<UserData>[] = useMemo(() => {
    const col: Column<UserData>[] = [
      {
        flex: 1,
        field: 'firstName',
        headerName: 'Name',
        renderCell: (data) => {
          const { firstName, image } = data.row as UserData;

          return (
            <div className="flex items-center" style={{ textTransform: 'capitalize' }}>
              <Avatar alt={firstName} src={image} className="mr-2">
                {firstName ? firstName.charAt(0) : 'U'}
              </Avatar>

              {firstName ? firstName : ''}
            </div>
          );
        },
      },
      { flex: 1, headerClassName: 'pl-custom-0', field: 'email', headerName: 'Email' },
      {
        flex: 1,
        field: 'roleSlug',
        headerName: 'Role',
        headerClassName: 'pl-custom-0',
        renderCell: (data) => {
          const { roleSlug } = data.row as UserData;
          return RoleSlugName[roleSlug];
        },
      },
      {
        flex: 1,
        field: 'active',
        headerName: 'Status',
        headerClassName: 'pl-custom-0',
        renderCell: (data) => {
          const { active } = data.row as UserData;
          return active ? (
            <div style={{ textTransform: 'capitalize' }}>Active</div>
          ) : (
            <div className="text-red-600">Inactive</div>
          );
        },
      },
    ];

    if (havePermission(['super-admin', 'psia-admin', 'psia-user', 'company-admin']))
      col.push({
        flex: 2,
        field: '',
        headerName: 'Actions',
        headerClassName: 'pl-custom-0',
        cellClassName: 'flex items-center',
        renderCell: (data) => {
          const { suspended } = data.row as UserData;
          return (
            <>
              <Button
                className=""
                disableElevation
                variant="contained"
                onClick={() => onEdit?.(data.row)}
              >
                <Edit className="mr-2" fontSize="small" /> Edit
              </Button>
              {havePermission(['psia-admin']) && (
                <>
                  {suspended ? (
                    <Button
                      className=""
                      disableElevation
                      variant="contained"
                      color="error"
                      onClick={() => onActive(data.row)}
                    >
                      <Delete className="mr-2" fontSize="small" /> Activate
                    </Button>
                  ) : (
                    <Button
                      className="ml-2"
                      disableElevation
                      variant="contained"
                      color="error"
                      onClick={() => {
                        confirm({ description: `Are you sure you want to do this?` })
                          .then(() => {
                            onDelete(data.row);
                          })
                          .catch(() => console.log('Deletion cancelled.'));
                      }}
                    >
                      <Delete className="mr-2" fontSize="small" /> Deactivate
                    </Button>
                  )}
                </>
              )}
            </>
          );
        },
      });

    return col;
  }, []);

  return (
    <section className={className}>
      {!disablHeader && (
        <div className="section-title">
          <div>Users</div>
          {extraHeaderContent}
        </div>
      )}
      <div className="box-container">
        <DataGrid
          disableColumnMenu
          rows={filterdata}
          loading={loading}
          columns={columns}
          pageSize={DEFAULT_PAGE_SIZE}
          autoHeight={true}
          disableSelectionOnClick={true}
        />
      </div>
    </section>
  );
}
