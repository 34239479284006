interface Props {
  className?: string;
}

export default function Footer(props: Props) {
  const { className } = props;
  return (
    <footer className={`bg-white text-center px-4 py-6 text-xs ${className}`}>
      ©2021 PLAI Test Tool. All Rights Reserved.
    </footer>
  );
}
