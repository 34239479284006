import { useEffect, useState } from 'react';

import {
  Modal,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
  FormHelperText,
  Autocomplete,
} from '@mui/material';

import { Controller, useForm, useWatch } from 'react-hook-form';
import toast from 'react-hot-toast';
import { AxiosError } from 'axios';
import * as Server from '../service/Server';
import ProgressBar from '../components/ProgressBar';
import {
  Profile,
  useAdapterStore,
  GetAdapterProfiles,
  CheckAdapter,
  DisconnectAdapter,
} from '../service/Adapter';
import { clearRecentTests } from '../service/Tests';

const regions: Record<string, string> = {
  Ohio: 'ohio',
  Africa: 'africa',
  Singapore: 'singapore',
  Scoul: 'scoul',
  Sydney: 'sydney',
  Frankfurt: 'frankfurt',
  Bahrain: 'bahrain',
  'Sao Paulo': 'saoPaulo',
};

interface ServerFormState {
  region: string;
}

interface Props {
  onSubmit?: (server: any) => void;
}

export default function ConnectionModel(props: Props) {
  const server = Server.useServerStore((state) => state);
  const adapter = useAdapterStore((state) => state);

  const serverConnected = !!server._id && !!server.availableIp;
  const connected = serverConnected && !!adapter.status;

  const { onSubmit } = props;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profiles, setProfiles] = useState<Profile[]>([]);

  const {
    handleSubmit: serverHandleSubmit,
    control: serverControl,
    reset,
  } = useForm<ServerFormState>({
    defaultValues: { region: server.region || '' },
  });
  const {
    handleSubmit: connectionHandleSubmit,
    control: connectionControl,
    setValue,
    reset: setConnection,
  } = useForm<Profile>({ defaultValues: adapter.request });

  const adapterUrlIndex = useWatch({ control: connectionControl, name: 'adapterUrl' });

  useEffect(() => {
    if (profiles.length !== 0 || !open) return;

    const loading = toast.loading('Loading Adapters');
    GetAdapterProfiles()
      .then((data) => setProfiles(data.data))
      .catch(({ response }: AxiosError) =>
        toast.error(response?.data.message ?? 'Server Creation Failed')
      )
      .finally(() => toast.dismiss(loading));
  }, [open, profiles.length]);

  useEffect(() => {
    const profile = profiles[Number(adapterUrlIndex)];
    if (profile) {
      setConnection(profile);
    }
  }, [adapterUrlIndex, profiles, setConnection]);

  async function StartServer({ region }: ServerFormState) {
    setLoading(true);
    const { data } = await toast
      .promise(Server.Create(region), {
        loading: 'Starting Server',
        success: () => 'Server Successfully Started',
        error: ({ response }: AxiosError) => response?.data.message ?? 'Server Creation Failed',
      })
      .finally(() => setLoading(false));

    if (data) RetryStartServer(data._id);
  }

  async function RetryStartServer(serverId = server._id) {
    setLoading(true);

    if (!serverId) return toast.error('No server Id specified Please Retry');

    await toast
      .promise(Server.CheckStatus(serverId), {
        loading: 'Server Setup Inprogress. It can take upto a minute',
        success: () => 'Server Successfully Connected',
        error: ({ response }: AxiosError) => {
          return response?.data.message ?? 'Server Connection Failed. Please Try again';
        },
      })
      .finally(() => setLoading(false));
  }

  async function AdapterSubmit(form: Profile) {
    setLoading(true);

    form.adapterUrl = `${form.protocol.toLowerCase()}://${form.adapterUrl}:${form.port}/`;
    if ((!server.availableIp && !server.networkStatus) || !!adapter.status || loading) {
      toast
        .promise(DisconnectAdapter(form), {
          loading: 'Adapter Disconnection Inprogress',
          success: ({ data }) => {
            onSubmit?.(data);
            reset({});
            clearRecentTests();
            return 'Adapter Successfully Disconnected';
          },
          error: ({ response }: AxiosError) =>
            response?.data.message ?? 'Adapter Disconnection Failed',
        })
        .finally(() => setLoading(false));
    } else {
      toast
        .promise(CheckAdapter(form), {
          loading: 'Adapter Setup Inprogress',
          success: ({ data }) => {
            onSubmit?.(data);
            return 'Adapter Successfully Connected';
          },
          error: ({ response }: AxiosError) => response?.data.message ?? 'Adapter Creation Failed',
        })
        .finally(() => setLoading(false));
    }
  }

  return (
    <>
      <Button
        variant="contained"
        color={connected ? 'error' : 'primary'}
        disableElevation
        onClick={() => setOpen(true)}
      >
        {connected ? 'Disconnect ' : 'Connect'}
      </Button>

      <Modal
        className="outline-0 flex items-center justify-center"
        open={open}
        onClose={() => setOpen(false)}
      >
        <section className="modal-container">
          {loading && <ProgressBar running={loading} />}

          <div>
            <div className="section-title text-3xl">Setup Unit Under Test</div>

            <form className="flex-grid no-space items-center">
              {adapter.status ? (
                <div> </div>
              ) : (
                <div className="column">
                  <FormControl fullWidth className="label label-small">
                    <InputLabel>Region</InputLabel>
                    <Controller
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { error },
                      }) => (
                        <>
                          <InputLabel>Region</InputLabel>
                          <Select
                            disabled={serverConnected}
                            size="small"
                            value={value}
                            label="Region"
                            name={name}
                            onBlur={onBlur}
                            inputRef={ref}
                            onChange={onChange}
                            error={!!error}
                          >
                            {Object.entries(regions).map(([name, value]) => (
                              <MenuItem key={value} value={value}>
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText className="text-red-800">{error?.message}</FormHelperText>
                        </>
                      )}
                      name="region"
                      control={serverControl}
                      rules={{ required: 'Field is required' }}
                    />
                  </FormControl>
                </div>
              )}

              <div className="column">
                <TextField
                  size="small"
                  fullWidth
                  className="label label-small"
                  value={server.availableIp ?? 'Not Connected'}
                  label="Server IP"
                  disabled={true}
                />
              </div>

              <div className="column w-auto">
                {serverConnected ? (
                  <Button
                    onClick={() => {
                      Server.Disconnect();
                      clearRecentTests();
                    }}
                    variant="contained"
                    fullWidth
                    size="large"
                    color="error"
                    disabled={!server.availableIp && !server.networkStatus}
                    className="button font-bold"
                  >
                    Disconnect Server
                  </Button>
                ) : (
                  <Button
                    onClick={serverHandleSubmit((form) => {
                      if (server._id) RetryStartServer(server._id);
                      else StartServer(form);
                    })}
                    variant="contained"
                    fullWidth
                    size="large"
                    disabled={connected || loading || serverConnected}
                    className="button font-bold"
                  >
                    {server._id ? 'Retry Server' : 'Create Server'}
                  </Button>
                )}
              </div>
            </form>

            <form className="mt-5">
              <div className="flex-grid my-2">
                <div className="column w-3/12 pl-0">
                  <FormControl fullWidth className="label label-small">
                    <Controller
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { error },
                      }) => (
                        <>
                          <InputLabel>Protocol</InputLabel>
                          <Select
                            size="small"
                            value={value || ''}
                            label="Protocol"
                            name={name}
                            onBlur={onBlur}
                            inputRef={() => {
                              // if (value === 'HTTP') {
                              //   consol;
                              // }
                              value == 'HTTP' ? setValue('port', 80) : setValue('port', 443);
                            }}
                            onChange={onChange}
                            error={!!error}
                            disabled={!server.availableIp || !!adapter.status}
                          >
                            <MenuItem value="HTTP">HTTP</MenuItem>
                            <MenuItem value="HTTPS">HTTPS</MenuItem>
                          </Select>
                          <FormHelperText className="text-red-800">{error?.message}</FormHelperText>
                        </>
                      )}
                      name="protocol"
                      control={connectionControl}
                      rules={{ required: 'Field is required' }}
                    />
                  </FormControl>
                </div>
                <div className="column w-6/12 pr-0">
                  <FormControl fullWidth className="label label-small">
                    <Controller
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { error },
                      }) => (
                        <>
                          <Autocomplete
                            freeSolo
                            size="small"
                            disablePortal
                            value={value ? value : ''}
                            options={profiles.map((profile) => profile.adapterUrl)}
                            disabled={!server.availableIp || !!adapter.status}
                            onKeyPress={onChange}
                            onChange={onChange}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Address"
                                value={value || ''}
                                name={name}
                                InputLabelProps={{ required: true }}
                                onBlur={onBlur}
                                inputRef={ref}
                                error={!!error}
                              />
                            )}
                          />

                          <FormHelperText className="text-red-800">{error?.message}</FormHelperText>
                        </>
                      )}
                      name="adapterUrl"
                      control={connectionControl}
                      rules={{
                        required: 'Field is required',
                        pattern: {
                          // eslint-disable-next-line  no-useless-escape
                          value: /^[ A-Za-z0-9_@./#&+-]*$/,
                          message: 'Not a valid address!',
                        },
                      }}
                    />
                  </FormControl>
                </div>
                <div className="column w-3/12 pr-0">
                  <FormControl fullWidth className="label label-small">
                    <Controller
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { error },
                      }) => (
                        <>
                          <TextField
                            size="small"
                            value={value || ''}
                            label="Port"
                            name={name}
                            onBlur={onBlur}
                            inputRef={ref}
                            onChange={onChange}
                            error={!!error}
                            disabled={!server.availableIp || !!adapter.status}
                          />

                          <FormHelperText className="text-red-800">{error?.message}</FormHelperText>
                        </>
                      )}
                      name="port"
                      control={connectionControl}
                      // rules={{ required: 'Field is required' }}
                    />
                  </FormControl>
                </div>
              </div>

              <FormControl fullWidth className="label label-small">
                <Controller
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { error },
                  }) => (
                    <>
                      <TextField
                        size="small"
                        value={value || ''}
                        label="Issuer Signature (optional)"
                        name={name}
                        onBlur={onBlur}
                        inputRef={ref}
                        onChange={onChange}
                        error={!!error}
                        disabled={!server.availableIp || !!adapter.status}
                      />

                      <FormHelperText className="text-red-800">{error?.message}</FormHelperText>
                    </>
                  )}
                  name="issuerSignature"
                  control={connectionControl}
                />
              </FormControl>

              <div className="flex-grid my-2">
                <div className="column w-6/12 pl-0">
                  <FormControl fullWidth className="label label-small">
                    <Controller
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { error },
                      }) => (
                        <>
                          <TextField
                            size="small"
                            value={value || ''}
                            label="Username"
                            name={name}
                            onBlur={onBlur}
                            inputRef={ref}
                            onChange={onChange}
                            error={!!error}
                            disabled={!server.availableIp || !!adapter.status}
                          />

                          <FormHelperText className="text-red-800">{error?.message}</FormHelperText>
                        </>
                      )}
                      name="username"
                      control={connectionControl}
                      // rules={{ required: 'Field is required' }}
                    />
                  </FormControl>
                </div>

                <div className="column w-6/12 pr-0">
                  <FormControl fullWidth className="label label-small">
                    <Controller
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { error },
                      }) => (
                        <>
                          <TextField
                            size="small"
                            value={value || ''}
                            label="Password"
                            name={name}
                            onBlur={onBlur}
                            inputRef={ref}
                            onChange={onChange}
                            error={!!error}
                            disabled={!server.availableIp || !!adapter.status}
                          />

                          <FormHelperText className="text-red-800">{error?.message}</FormHelperText>
                        </>
                      )}
                      name="password"
                      control={connectionControl}
                      // rules={{ required: 'Field is required' }}
                    />
                  </FormControl>
                </div>
              </div>
            </form>
            {connected ? (
              <div>
                {' '}
                <Button
                  onClick={connectionHandleSubmit(AdapterSubmit)}
                  variant="contained"
                  fullWidth
                  color="error"
                  size="small"
                  className="button font-bold mt-5"
                >
                  Disconnect Adapter
                </Button>{' '}
              </div>
            ) : (
              <Button
                onClick={connectionHandleSubmit(AdapterSubmit)}
                variant="contained"
                fullWidth
                size="large"
                disabled={
                  (!server.availableIp && !server.networkStatus) || !!adapter.status || loading
                }
                className="button font-bold mt-5"
              >
                Connect Adapter
              </Button>
            )}
          </div>

          <div className="mt-6">
            <div className="flex">
              <div className="mb-2  flex justify-start column w-6/12">Console </div>
              <div className="mb-2 w-6/12  flex justify-end">
                {connected ? 'Connected' : `Not Connected`}
              </div>
            </div>
            <TextField
              disabled={true}
              className="text-gray-800"
              fullWidth
              multiline
              rows={4}
              value={adapter.response || ''}
            />
          </div>
        </section>
      </Modal>
    </>
  );
}
