import { memo, useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  AccordionSummary,
  AccordionDetails,
  Accordion,
} from '@mui/material';
import toast from 'react-hot-toast';
import { ExpandMore } from '@mui/icons-material';
import { AxiosError } from 'axios';

import { Suite, SuiteTree, Test, useTestsStore } from '../service/Tests';
import { useServerStore } from '../service/Server';
import { useAdapterStore } from '../service/Adapter';
import { Company, getcompanies } from '../service/Company';
import { havePermission, useUserStore } from '../service/User';

function TestsContainer(props: {
  className?: string;
  suiteTree: SuiteTree[];
  onChange: (data: { tests: Test[]; suite?: Suite; company?: Company }) => void;
}) {
  const { onChange, suiteTree, className } = props;

  const recenttest = useTestsStore((state) => state.recenttest);
  const adapterstatus = useAdapterStore((state) => state.status);
  const connected = useServerStore(
    ({ _id, availableIp }) => !!_id && !!availableIp && !!adapterstatus
  );
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [selectedTests, setSelectedTests] = useState<Test[]>([]);
  const [selectedSuit, setSelectedSuit] = useState<Suite | undefined>(recenttest?.suite);
  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>(recenttest?.company);

  useEffect(() => {
    // console.log(selectedSuit?.name, '---------Log------afterChange-----', selectedTests?.length);
    onChange({
      tests: selectedTests.sort((a, b) => a.testRunOrder - b.testRunOrder),
      suite: selectedSuit,
      company: selectedCompany,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTests, selectedSuit]);

  useEffect(() => {
    if (loading && companies.length === 0)
      getcompanies()
        .then((data) => {
          const companylist = data.filter((ele) => !ele?.suspended);
          setCompanies(companylist);
        })
        .catch(({ response }: AxiosError) =>
          toast.error(response?.data.message ?? 'Some Error Occured')
        )
        .finally(() => setLoading(false));
  }, [companies, loading]);

  useEffect(() => {
    setSelectedTests([]);
    if (selectedSuit?.validForCertification) {
      const suite = suiteTree.find((suite) => suite._id === selectedSuit?._id);
      if (suite) {
        console.log('-----------log-------here');
        setSelectedTests(
          suite.types.flatMap((type) => type.tests.filter((test) => test.certification_required))
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSuit, setSelectedTests]);

  // useEffect(() => {
  //   // console.log(selectedTests, '------selectedTests-----');
  // }, [selectedTests]);

  useEffect(() => {
    if (selectedCompany && !loading) {
      setSelectedTests([]);
      setSelectedSuit(undefined);
    }
  }, [selectedCompany]);

  return (
    <div className={`box-container ${className}`}>
      <div className="p-4">
        {havePermission(['super-admin']) && (
          <FormControl fullWidth className="mb-2">
            <InputLabel>Company</InputLabel>
            <Select
              value={selectedCompany?._id ?? ''}
              disabled={!connected}
              label="Company"
              onChange={({ target }) => {
                const company = companies.find((company) => company._id === target.value);
                if (company) setSelectedCompany(company);
              }}
            >
              {companies.map((company) => (
                <MenuItem key={company._id} value={company._id}>
                  {company.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <FormControl fullWidth className="mb-2">
          <InputLabel>Suites</InputLabel>
          <Select
            value={selectedSuit?._id ?? ''}
            disabled={!connected || (!selectedCompany && havePermission(['super-admin']))}
            label="Suites"
            onChange={({ target }) => {
              const suit = suiteTree.find((suit) => suit._id === target.value);
              if (suit) setSelectedSuit(suit);
            }}
          >
            {suiteTree.map((tree) => (
              <MenuItem key={tree._id} value={tree._id}>
                {tree.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="mt-2">
        {suiteTree
          .find((suite) => suite._id === selectedSuit?._id)
          ?.types.map((type) => {
            return (
              <Accordion key={type._id} elevation={2} className="rounded-none shadow border-t">
                <AccordionSummary expandIcon={<ExpandMore />}> {type.name} </AccordionSummary>
                <AccordionDetails>
                  {type.tests.map((test) => {
                    const checked = selectedTests.some((t) => t._id === test._id);

                    return (
                      <div key={test._id} className="pt-2 pb-6 border-b">
                        <FormControlLabel
                          value="end"
                          disabled={
                            !connected ||
                            (selectedSuit?.validForCertification && test.certification_required) ||
                            test.isDataScript
                          }
                          control={
                            <Checkbox
                              value={test._id}
                              onChange={(_, check) => {
                                if (check) {
                                  setSelectedTests((prev) => [...prev, test]);
                                } else {
                                  setSelectedTests((prev) =>
                                    prev.filter((t) => t._id !== test._id)
                                  );
                                }
                              }}
                              color="primary"
                              checked={checked || test.isDataScript}
                            />
                          }
                          label={test.title}
                          className="text-primary"
                          labelPlacement="end"
                        />
                        <div className="ml-8 text-sm">
                          <div>{test.name}</div>
                        </div>
                      </div>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            );
          })}
      </div>
    </div>
  );
}

const TestsContainerMemo = memo(TestsContainer);
export default TestsContainerMemo;
