import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormHelperText,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Company, getcompanies } from '../../service/Company';
import {
  UserData,
  createUser,
  updateUser,
  getRoles,
  Role,
  havePermission,
  useUserStore,
} from '../../service/User';

interface Props {
  initialValue?: Partial<FormState>;
  onCancel?: () => void;
  onSubmit?: (value: FormState) => void;
}

interface FormState extends UserData {
  company: Company['_id'];
  password?: string;
}

function generatePassword() {
  var length = 15,
    charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_-!.@#$%',
    retVal = '';
  for (var i = 0, n = charset.length; i < length; ++i)
    retVal += charset.charAt(Math.floor(Math.random() * n));
  return retVal;
}

export default function AddUser(props: Props) {
  const { initialValue, onCancel, onSubmit: AfterSubmit } = props;
  const user = useUserStore.getState().userInfo;
  const { handleSubmit, control, setValue } = useForm<FormState>({
    defaultValues: {
      ...initialValue,
      password: Object.keys(initialValue ?? {}).length === 0 ? generatePassword() : '',
    },
  });

  if (initialValue?.active != undefined) {
    setValue('active', initialValue.active ? true : false);
  } else {
    console.log('--------Here------');
    setValue('active', true);
  }

  const [companies, setCompanies] = useState<Company[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [loading, setLoading] = useState<string>('');

  function onSubmit(form: FormState) {
    const create = !Object.keys(initialValue ?? {}).length;
    if (!form.password) {
      delete form['password'];
    }
    if (!form.company && user) {
      form.company = user.company;
    }
    toast.promise(create ? createUser(form) : updateUser(form), {
      loading: 'Loading',
      success: () => {
        AfterSubmit?.(form);
        return `Successfully ${create ? 'Created' : 'Updated'}`;
      },
      error: ({ response }: AxiosError) => response?.data.message ?? 'Some Error Occured',
    });
  }

  useEffect(() => {
    setLoading(toast.loading('Loading'));

    const companyP = getcompanies();
    const roleP = getRoles();

    Promise.all([companyP, roleP])
      .then(([companylist, roleslist]) => {
        companylist = companylist.filter((ele) => !ele?.suspended);
        setCompanies(companylist);
        setRoles(roleslist);
      })
      .catch(({ response }: AxiosError) =>
        toast.error(response?.data.message ?? 'Some Error Occured')
      )
      .finally(() => {
        toast.dismiss(loading);
        setLoading('');
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCompanies, setRoles]);

  if (loading) return <div>Loading...</div>;

  return (
    <div className="box-container">
      <form className="w-10/12 mx-auto flex-grid">
        <div className="column w-6/12 mt-4">
          <FormControl fullWidth className="label">
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error },
              }) => (
                <>
                  <TextField
                    size="small"
                    value={value}
                    label="First Name"
                    name={name}
                    onBlur={onBlur}
                    inputRef={ref}
                    onChange={onChange}
                    error={!!error}
                  />

                  <FormHelperText className="text-red-800">{error?.message}</FormHelperText>
                </>
              )}
              name="firstName"
              control={control}
              rules={{ required: 'Field is required' }}
            />
          </FormControl>
        </div>
        <div className="column w-6/12 mt-4">
          <FormControl fullWidth className="label">
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error },
              }) => (
                <>
                  <TextField
                    size="small"
                    value={value}
                    label="Last Name"
                    name={name}
                    onBlur={onBlur}
                    inputRef={ref}
                    onChange={onChange}
                    error={!!error}
                  />

                  <FormHelperText className="text-red-800">{error?.message}</FormHelperText>
                </>
              )}
              name="lastName"
              control={control}
              rules={{ required: 'Field is required' }}
            />
          </FormControl>
        </div>
        <div className="column w-6/12 mt-4">
          <FormControl fullWidth className="label">
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error },
              }) => (
                <>
                  <TextField
                    size="small"
                    value={value}
                    label="Email"
                    name={name}
                    onBlur={onBlur}
                    inputRef={ref}
                    onChange={onChange}
                    error={!!error}
                  />

                  <FormHelperText className="text-red-800">{error?.message}</FormHelperText>
                </>
              )}
              name="email"
              control={control}
              rules={{
                required: 'Field is required',
                pattern: {
                  // eslint-disable-next-line  no-useless-escape
                  value: /^/g,
                  message: 'Not a valid Email',
                },
              }}
            />
          </FormControl>
        </div>
        {havePermission(['psia-user', 'psia-admin']) && (
          <div className="column w-6/12 mt-4">
            <FormControl fullWidth className="label">
              <Controller
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { error },
                }) => (
                  <>
                    <InputLabel>Company</InputLabel>
                    <Select
                      size="small"
                      value={value}
                      label="Company"
                      name={name}
                      onBlur={onBlur}
                      inputRef={ref}
                      onChange={onChange}
                      error={!!error}
                      defaultValue={value}
                    >
                      {companies.map((company) => (
                        <MenuItem value={company._id} key={company._id}>
                          {company.name}
                        </MenuItem>
                      ))}
                    </Select>

                    <FormHelperText className="text-red-800">{error?.message}</FormHelperText>
                  </>
                )}
                name="company"
                control={control}
                rules={{ required: 'Field is required' }}
              />
            </FormControl>
          </div>
        )}
        <div className="column w-6/12 mt-4">
          <FormControl fullWidth className="label">
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error },
              }) => (
                <>
                  <InputLabel>Role</InputLabel>
                  <Select
                    size="small"
                    value={value}
                    label="Role"
                    name={name}
                    onBlur={onBlur}
                    inputRef={ref}
                    onChange={onChange}
                    defaultValue={value}
                    error={!!error}
                  >
                    {roles.map(({ slug, name }) => (
                      <MenuItem value={slug} key={slug}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>

                  <FormHelperText className="text-red-800">{error?.message}</FormHelperText>
                </>
              )}
              name="roleSlug"
              control={control}
              rules={{ required: 'Field is required' }}
            />
          </FormControl>
        </div>
        <div className="column w-6/12 mt-4">
          <FormControl fullWidth className="label">
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error },
              }) => (
                <>
                  <InputLabel>Status</InputLabel>
                  <Select
                    size="small"
                    value={value}
                    defaultValue={initialValue ? initialValue.active : true}
                    name={name}
                    onBlur={onBlur}
                    inputRef={ref}
                    onChange={onChange}
                    error={!!error}
                    label="Status"
                  >
                    <MenuItem value="true">Active</MenuItem>
                    <MenuItem value="false">InActive</MenuItem>
                  </Select>

                  <FormHelperText className="text-red-800">{error?.message}</FormHelperText>
                </>
              )}
              name="active"
              control={control}
              // rules={{ required: 'Field is required' }}
            />
          </FormControl>
        </div>
        <div className="column w-6/12 mt-4">
          <FormControl fullWidth className="label">
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error },
              }) => (
                <>
                  <TextField
                    type="number"
                    size="small"
                    value={value}
                    label="Contact Number"
                    name={name}
                    onBlur={onBlur}
                    inputRef={ref}
                    onChange={onChange}
                    error={!!error}
                  />

                  <FormHelperText className="text-red-800">{error?.message}</FormHelperText>
                </>
              )}
              name="phone"
              control={control}
              // rules={{ required: 'Field is required' }}
            />
          </FormControl>
        </div>
        <div className="column w-6/12 mt-4">
          <FormControl fullWidth className="label">
            <Controller
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error },
              }) => (
                <>
                  <TextField
                    size="small"
                    value={value}
                    label="Password"
                    name={name}
                    onBlur={onBlur}
                    inputRef={ref}
                    onChange={onChange}
                    error={!!error}
                  />

                  <FormHelperText className="text-red-800">{error?.message}</FormHelperText>
                </>
              )}
              name="password"
              control={control}
              rules={{
                pattern: {
                  // eslint-disable-next-line  no-useless-escape
                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                  message:
                    'Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character',
                },
              }}
            />
          </FormControl>
        </div>
        <div className="column w-full flex justify-center mt-4">
          <Button
            disableElevation
            size="large"
            className="mx-2"
            variant="contained"
            color="error"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            disableElevation
            size="large"
            className="mx-2"
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}
