import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import { TESTS_URL, SUITS_URL } from '../routes';
import AddTypeModel from '../section/AddType';
import { getTypes, SuiteTree, Type, deleteType } from '../service/Tests';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { havePermission } from '../service/User';
import { useConfirm } from 'material-ui-confirm';

export default function TypesList() {
  const { suitid } = useParams<{ suitid: string }>();
  const [types, setType] = useState<SuiteTree[]>([]);
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const [activeType, setActive] = useState<Type>();
  const confirm = useConfirm();

  useEffect(() => {
    if (loading && types.length === 0) {
      const loadingtoast = toast.loading('Loading Types');
      getTypes(suitid)
        .then((tree) => {
          setType(tree);
        })
        .catch(({ response }: AxiosError) =>
          toast.error(response?.data.message ?? 'Some Error Occured')
        )
        .finally(() => {
          toast.dismiss(loadingtoast);
          setLoading(false);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [types, loading]);

  return (
    <div className="flex flex-col">
      <section>
        <div className="section-title">
          <span>
            <Link to={SUITS_URL}>Suites &gt;&gt;</Link>Profile
          </span>
        </div>
        <div className="box-container flex">
          <AddTypeModel
            suitId={suitid}
            edit={edit}
            initialState={activeType}
            setActive={setActive}
            setEdit={setEdit}
            AfterSubmit={() => {
              setType([]);
              setLoading(true);
            }}
          />
        </div>
        <div className="flex-grid mt-6">
          {types.map((type) => (
            <div key={type._id} className="column w-4/12">
              <div className="my-1 flex-grid box-container items-center">
                <div className="column">
                  <Link
                    to={TESTS_URL.replace(':suitid', suitid).replace(':typeid', type._id)}
                    className="w-full box-container block p-6"
                  >
                    {type.name}
                  </Link>
                </div>
                {havePermission(['psia-admin']) && (
                  <div className="column w-auto">
                    <Stack
                      className="edit-del-button"
                      direction="row"
                      alignItems="center"
                      spacing={1}
                    >
                      <Button
                        style={{ color: '#76B6B0' }}
                        onClick={(event) => {
                          setEdit(true);
                          setActive(type);
                        }}
                      >
                        <EditIcon fontSize="small" sx={{ mr: 0.2 }} />
                      </Button>
                      <Button
                        // alignItems="center"
                        style={{ color: '#FF4D5E' }}
                        onClick={(event) => {
                          confirm({ description: `Are you sure you want to do this?` })
                            .then(() => {
                              toast.promise(deleteType(type._id), {
                                loading: 'Loading',
                                success: () => {
                                  setType([]);
                                  setLoading(true);
                                  return `Successfully Deleted`;
                                },
                                error: ({ response }: AxiosError) =>
                                  response?.data.message ?? 'Some Error Occured',
                              });
                            })
                            .catch(() => console.log('Deletion cancelled.'));
                        }}
                      >
                        <DeleteIcon fontSize="small" sx={{ mr: 0.2 }} />
                      </Button>
                    </Stack>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
