import { AxiosError } from 'axios';
import create from 'zustand';
import api, { Response } from '../utils/api';
import { FormatDate } from '../utils/format';
import { Company } from './Company';
import { useServerStore } from './Server';

export type SuiteTree = Suite & { types: (Type & { tests: Test[] })[] };

export interface Suite {
  name: string;
  slug: string;
  suspended: boolean;
  validForCertification: boolean;
  _id: string;
}

export interface Type {
  _id: string;
  name: string;
  suitId?: string;
  suspended?: boolean;
}

export type Criteria = 'Success' | 'Fail';

interface Field {
  key: string;
  required: boolean; // type: 'string';
  type: 'number' | 'string'; // key: 'chi_id';
  value: any; // required: false;
  dependOnTestId: Test['_id'];
  defaultValue: any;
}

export interface Test {
  _id: string; // '61e841d95fbc96f40fde6de5';
  suspended: boolean; // false;
  requestType?: ('GET' | 'PUT' | 'POST' | 'DELETE')[];
  title: string; // 'Create Credential on Credential Holder';
  name: string; // 'create_cred_on_cred_hr';
  description: string; // 'This function Performs create_cred_on_cred_hrfunction';
  suitId: Suite['_id']; // '61d595ecabf95638444aa035';
  typeId: Type['_id']; // '61deca33defc0548f560068d';
  fields: Field[];
  criteria: Criteria; // 'Success';
  testRunOrder: number; // 48;
  script: string; // 'Here is dumy data but Scripts will Validate Later';
  certification_required: boolean; // true;
  isDataScript: boolean;
  status: number;
}

export interface TestPayload extends Test {
  id?: string;
}

export interface History {
  request: [Request];
  response: TestResult['responseData'];
  _id: string;
  title: string;
  createdAt: string;
  updatedAt: string;
}
export interface TestResult {
  responseData: ({
    Operation: string;
    Message: string;
    Status: Criteria;
    Timestamp: string;
    RequestType: String;
    id: String;
    Data?: {
      uid: string;
      payload_data: string;
    }[];
  } & Record<string, any>)[];
  testProcessId: string;
}

export interface ResultResponse {
  // response: {
  Data: [];
  Error: string;
  ErrorType: '';
  Message: string;
  Operation: string;
  RequestType: string;
  Status: string;
  Timestamp: string;
  chi_id: string;
  id: string;
  new_role: string;
  // };
}

interface Request {
  tests: [{ testId: string; companyId?: string } & Record<string, string | number>];
}

export type TestStatus = 'Preparing' | 'Queue' | 'Inprogress' | 'Fail' | 'Success';

interface TestStoreState {
  recenttest?: {
    tests?: TestPayload[];
    suite?: Suite;
    company?: Company;
    status?: TestStatus;
    result?: Record<string, TestResult>;
  };
  running: boolean;
  testProcessId?: string;
  dataScript?: boolean;
  scriptResult?: Record<string, TestResult>;
  scriptTest?: Test[];
}

interface AllsuiteTests {
  tests: [Test];
  occupiedRunOrders: number[];
}

export const useTestsStore = create<TestStoreState>(() => ({
  recenttest: undefined,
  running: false,
  dataScript: true,
  scriptTest: undefined,
  scriptResult: undefined,
}));

export async function runTests(
  tests: TestPayload[],
  suite: Suite,
  company?: Company,
  isDataScript?: boolean
) {
  const serverId = useServerStore.getState()._id;
  let testProcessId = useTestsStore.getState().testProcessId;
  let scriptResult = isDataScript ? undefined : useTestsStore.getState().scriptResult;
  let results = !isDataScript ? undefined : useTestsStore.getState().recenttest?.result;
  let scriptTest = !isDataScript ? undefined : useTestsStore.getState().scriptTest;
  let dataScript = !isDataScript ? undefined : useTestsStore.getState().dataScript;
  const requests: Request[] = Object.values(tests).map((test) => {
    return {
      tests: [
        {
          ...test.fields.reduce((acc, { key, type, value }) => {
            acc[key] = value ? (type.toLowerCase() === 'number' ? Number(value) : value) : '';
            return acc;
          }, {} as Record<string, string | number>),
          ...(company?._id && { companyId: company?._id }),
          testId: test._id,
        },
      ],
      companyId: company?._id,
    };
  });
  useTestsStore.setState(
    {
      recenttest: { tests, suite, company, status: 'Queue', result: results },
      running: true,
      scriptResult: scriptResult,
      testProcessId: 'none',
      dataScript: dataScript ? dataScript : false,
      scriptTest: scriptTest,
    },
    true
  );

  for await (const request of requests) {
    const { data: response } = await api
      .post<Response<TestResult>>(`/engine/runChildScript/${serverId}`, {
        ...request,
        ...(testProcessId && { testProcessId }),
      })
      .catch((error: AxiosError) => {
        useTestsStore.setState((state) => ({
          recenttest: { ...state.recenttest, status: 'Fail' },
          running: false,
        }));
        throw error;
      });

    if (!testProcessId) {
      testProcessId = response.data.testProcessId;
      useTestsStore.setState({ testProcessId: testProcessId });
    }
    if (isDataScript) {
      useTestsStore.setState(({ scriptResult }) => ({
        scriptResult: {
          ...scriptResult,
          [request.tests[0].testId]: response.data,
        },
      }));
    } else {
      useTestsStore.setState(({ recenttest }) => ({
        recenttest: {
          ...recenttest,
          result: {
            ...recenttest?.result,
            [request.tests[0].testId]: response.data,
          },
          status: 'Inprogress',
        },
      }));
    }
    useTestsStore.setState({ dataScript: dataScript ? dataScript : false });
  }

  useTestsStore.setState((state) => ({
    running: false,
    recenttest: {
      ...state.recenttest,
      ...state.scriptResult,
      ...state.scriptTest,      
      status: 'Success',
    },
  }));
  return '';
}

export async function clearRecentTests() {
  useTestsStore.setState({
    recenttest: undefined,
    testProcessId: '',
    running: false,
    dataScript: true,
    scriptTest: undefined,
    scriptResult: undefined,
  });
}

export async function getHistory(): Promise<History[]> {
  const { data: response } = await api.get<
    Response<(Omit<History, 'response'> & { response: TestResult['responseData'] })[]>
  >(`/engine`);

  const FormatResult = (tests: TestResult['responseData']): TestResult['responseData'] => {
    return tests?.filter(Boolean)?.map((test) => ({
      ...test,
      Timestamp: FormatDate(test.Timestamp),
    }));
  };

  return response.data.map((history) => ({
    ...history,
    response: FormatResult(history.response as any),
  }));
}

export async function getTestsTree(): Promise<SuiteTree[]> {
  const { data: response } = await api
    .get<Response<SuiteTree[]>>(`/suit-tests/findTestWithSuits`)
    .catch((error: AxiosError) => {
      throw error;
    });

  return response.data;
}

export async function getSuits(): Promise<Suite[]> {
  const { data: response } = await api
    .get<Response<Suite[]>>(`/suits`)
    .catch((error: AxiosError) => {
      throw error;
    });

  return response.data;
}

export async function addSuit(name: string, validForCertification: boolean): Promise<any> {
  const { data: response } = await api
    .post(`/suits`, { name, validForCertification })
    .catch((error: AxiosError) => {
      throw error;
    });

  return response.data;
}

export async function updateSuit(
  suitId: string,
  name: string,
  validForCertification: boolean
): Promise<any> {
  const { data: response } = await api
    .put(`/suits/${suitId}`, { name, validForCertification })
    .catch((error: AxiosError) => {
      throw error;
    });

  return response.data;
}

export async function deleteSuit(suitId: string): Promise<any> {
  const { data: response } = await api.delete(`/suits/${suitId}`).catch((error: AxiosError) => {
    throw error;
  });

  return response.data;
}

export async function getTests(typeId: string): Promise<AllsuiteTests> {
  const { data: response } = await api
    .get<Response<AllsuiteTests>>(`suit-tests?typeId=${typeId}`)
    .catch((error: AxiosError) => {
      throw error;
    });

  return response.data;
}
export async function getTestWithSuitId(suitId: string): Promise<AllsuiteTests> {
  const { data: response } = await api
    .get<Response<AllsuiteTests>>(`suit-tests?suitId=${suitId}`)
    .catch((error: AxiosError) => {
      throw error;
    });

  return response.data;
}

export async function addTest(formVal: any): Promise<Test[]> {
  const { data: response } = await api
    .post<Response<Test[]>>(`suit-tests`, formVal)
    .catch((error: AxiosError) => {
      throw error;
    });

  return response.data;
}

export async function updateTest(formVal: any): Promise<Test[]> {
  const { data: response } = await api
    .put<Response<Test[]>>(`suit-tests/${formVal?._id}`, formVal)
    .catch((error: AxiosError) => {
      throw error;
    });

  return response.data;
}

export async function deleteTest(testId: string): Promise<Test[]> {
  const { data: response } = await api
    .delete<Response<Test[]>>(`suit-tests/${testId}`)
    .catch((error: AxiosError) => {
      throw error;
    });

  return response.data;
}

export async function getTypes(suitId: string): Promise<SuiteTree[]> {
  const { data: response } = await api
    .get<Response<SuiteTree[]>>(`/test-types?suitId=${suitId}`)
    .catch((error: AxiosError) => {
      throw error;
    });

  return response.data;
}

export async function addType(suitId: string, name: string): Promise<any> {
  const { data: response } = await api
    .post(`/test-types`, { name, suitId })
    .catch((error: AxiosError) => {
      throw error;
    });

  return response.data;
}

export async function updateType(typeId: string, suitId: string, name: string): Promise<any> {
  const { data: response } = await api
    .put(`/test-types/${typeId}`, { name, suitId })
    .catch((error: AxiosError) => {
      throw error;
    });

  return response.data;
}

export async function deleteType(typeId: string): Promise<any> {
  const { data: response } = await api
    .delete(`/test-types/${typeId}`)
    .catch((error: AxiosError) => {
      throw error;
    });

  return response.data;
}
